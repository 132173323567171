import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';
import useFetch from "../utils/useFetch";
import SvgIcon from "./SvgIcon";
import settings from "../utils/settings";
import SearchBar from "./SearchBar";
import {formatDateDifference} from "../utils/formatDateToD";

import './HeaderMobile.css'
const Header = () => {
  const [btn, setBtn] = useState(true)
    , [notify, setNotify] = useState(false)
    , [showSearchBar, setShowSearchBar] = useState(false)
    , [isAdmin, setIsAdmin] = useState(false)
    , [isSuper, setIsSuper] = useState(false)
    , [todos, setTodos] = useState([])
    , { setFetch: setLogout } = useFetch('user/setLogout','1')
    , { setFetch: getNotifyCount, dataResponse: getNotifyCountData} = useFetch('notify/getNotifyCount','1')
    , { setFetch: getCheckRights, dataResponse: getCheckRightsData} = useFetch('user/getCheckRights','1')
    , buttonRef = useRef(null)
    , handleClick = () => setBtn((prevBtn) => !prevBtn)
    , navigate = useNavigate()
    , handleClickBtnExit = () => {
        setLogout()
        const vecLS=['hash','UID'];
        vecLS.forEach(e=>{
          localStorage.removeItem(e)
        })
        navigate('/auth')
      }
    , handleCloseSrch = () => {
        setShowSearchBar(false)
      }
  ;
  useEffect(() => {
    setInterval(()=>{
      getNotifyCount()
    },1000*60*2)//1000*60*2
  }, []);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      // Проверяем, что клик был не по кнопке --или меню--
      if (
        // menuRef.current &&
        // !menuRef.current.contains(event.target) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target)
      ) {
        setBtn(true);
      }
    };
    if (!btn) document.addEventListener('click', handleOutsideClick)
    return () => document.removeEventListener('click', handleOutsideClick)
  }, [btn]);
  useEffect(() => {
    if(
      getNotifyCountData
      && getNotifyCountData.s===1
    ){
      const dt=parseInt(getNotifyCountData.d);
      if(dt>0){
        setNotify(true)
      }
      if(dt===0){
        setNotify(false)
      }
    }
  }, [getNotifyCountData]);
  useEffect(() => {
    if(todos && todos.length>0)setShowSearchBar(true)
  }, [todos]);

  useEffect(() => {
    getCheckRights()
  }, []);

  useEffect(() => {
    if(getCheckRightsData && getCheckRightsData.s===1){
      const r=parseInt(getCheckRightsData.d);
      setIsAdmin(r > 0)
      setIsSuper(r === 2)
    }
  }, [getCheckRightsData]);

  return (
    <header className="cnt mobPDx fx ac fsb">
      <Link className="fx ac gap.4 logo" to="/">
        <SvgIcon hash="todo" width="38" /><span className="mobDn">{settings.REACT_APP_APP_NAME}</span>
      </Link>

      {/*<span className="pr fx ac hSear">
        <input
          className="bgf bx"
          placeholder="Поиск"
          onFocus={handleSearch}
        />
        <button
          className="pa btn br50"
          onClick={handleSearch}
        >
          <SvgIcon hash="search" width="24" />
        </button>
      </span>*/}

      <SearchBar cb={setTodos} type="all"/>

      <span className={`pr hBtn ${btn ? 'hBtnCl' : ''}`}>
        <button
          ref={buttonRef}
          className={`pr btn${(notify) ? ' hNtfCl':''}`}
          onClick={handleClick}
        >
          <SvgIcon hash="user" width="38" />
        </button>
        {!btn && (
          <ul /*ref={menuRef}*/ className="pa z4 fx fdc gap1 bgf pd br tl">
            <li>
              <Link className="fx ac gap.4" to="/">
                <SvgIcon hash="home" width="24"/>
                Главная
              </Link>
            </li>
            <li>
              <Link className="fx ac gap.4" to="/create-task">
                <SvgIcon hash="addTodo" width="24"/>
                Создать задачу
              </Link>
            </li>
            <li>
              <Link className="fx ac gap.4" to="/profile">
                <SvgIcon hash="userProfile" width="24"/>
                Профиль
              </Link>
            </li>
            <li>
              <Link className="fx ac gap.4" to="/my-todos">
                <SvgIcon hash="myTodo" width="24"/>
                Мои задачи
              </Link>
            </li>
            {
              isAdmin && <li>
                <Link className="fx ac gap.4" to="/manage-users">
                  <SvgIcon hash="users" width="24"/>
                  Пользователи
                </Link>
              </li>
            }
            {
              isSuper && <li>
                <Link className="fx ac gap.4" to="/companies">
                  <SvgIcon hash="company" width="24"/>
                  Компании
                </Link>
              </li>
            }
            <li>
              <Link className={`pr fx ac gap.4 ${(notify) ? ' hNtfCl' : ''}`} to="/notify">
                <SvgIcon hash="notify" width="24"/>
                Оповещения
              </Link>
            </li>
            <li>
              <button
                className="fx ac gap.4 cb btn pd0"
                onClick={handleClickBtnExit}
              >
                <SvgIcon hash="exit" width="24"/>
                Выйти
              </button>
            </li>
          </ul>
        )}
      </span>
      {
        (todos && showSearchBar) && (
          todos.length > 0
            ? <div className="pf z10 bgf pd hSearDiv">
              <button
                className="bgb cf pd w1 fwb"
                onClick={handleCloseSrch}
              >Закрыть
              </button>
              {
                /**
                 * {"id":239,"title":"qweee","datetime":"2024-10-16 11:34:04","state":1,"UID":4439,"description":"","updated":"2024-11-22 13:36:08","deadline":"2024-10-25","resp":4430,"json":"{\\"files\\":[[\\"2024-10-16-07-10-02-329_b784ee89d3f03a5dedf8a0d9681fbccf.7z\\",\\"7.26 KB\\"],[\\"2024-10-16-07-10-02-330_a5a267bc359e405d44617ec3f5cb1282.xlsx\\",\\"15.53 KB\\"],[\\"2024-10-16-07-10-02-331_952edce3b4994096b2667152f89c1f05.zip\\",\\"23.4 KB\\"]],\\"team\\":\\"4430\\",\\"dep\\":7}","resp_name":""}
                 */
                <ul className="mt">
                  {
                    todos.map(e => (
                      <li key={e.id}>
                        <Link onClick={handleCloseSrch} to={`/task/${e.id}`}>{e.title}</Link>
                        <small>
                          {
                            {
                              '0': <q className="s_ s0">удалена</q>,
                              '1': <q className="s_ s1">новая</q>,
                              '2': <q className="s_ s2">в работе</q>,
                              '3': <q className="s_ s3">завершена</q>
                            }[String(e.state)] || null
                          }
                          {e.resp_name && <q>отв.: {e.resp_name}</q>}
                          <q>дедлайн: {formatDateDifference(e.deadline)}</q>
                        </small>
                      </li>
                    ))
                  }
                </ul>
              }
            </div>
            : ''
        )
      }
    </header>
  );
};

export default Header;