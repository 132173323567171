// src/App.js
import React, { /*useEffect, useState,*/ Suspense,  lazy } from 'react';
import { Routes, Route } from 'react-router-dom';
import AuthPage from './pages/Auth/AuthPage';
import ProfilePage from './pages/Profile/ProfilePage';
import CreateTaskPage from './pages/TaskCreation/CreateTaskPage';
import TaskViewPage from './pages/TaskView/TaskViewPage';
import TaskEditPage from './pages/TaskView/TaskEditPage';
import CompanyPage from "./pages/Company/CompanyPage";
// import HomePage from './pages/Home/HomePage';
// import HomeMobilePage from './pages/Home/HomeMobilePage';
import PrivateRoute from './components/PrivateRoute';
// import SearchBar from "./components/SearchBar";
// import Header from "./components/Header";
import NotifyPage from "./pages/Notify/NotifyPage";
import MyTodosPage from "./pages/TaskView/MyTodosPage";
import ManageUsersPage from "./pages/Users/ManageUsersPage";
import loader from './utils/loader';
import useIsMobile from "./utils/checkMobile";
import './utils/customAlert';
import './style/App.css';

const HomePage = lazy(() => import('./pages/Home/HomePage'))
  , HomeMobilePage = lazy(() => import('./pages/Home/HomeMobilePage'))
  , Header = lazy(() => import('./components/Header'))
  , HeaderMobile = lazy(() => import('./components/HeaderMobile'))
;
// Используем require.context для автоматического импорта всех компонентов из папки pages
const pages = require.context('./pages', true, /\.js$/);
const routes = pages.keys().map((path) => {
  const Component = React.lazy(() => import(`./pages/${path.replace('./', '')}`));
  const routePath = path
    .replace('./', '/')    // Убираем './' в начале
    .replace('Page.js', '') // Убираем 'Page.js' в конце
    .toLowerCase();         // Приводим к нижнему регистру
  return { path: routePath, Component };
});

function App() {
  const isMobile = useIsMobile();
  loader()
  return (
    <Suspense fallback={<div>Загрузка...</div>}>
      <Routes>
        {routes.map(({ path, Component }, index) => (
          <Route key={index} path={path} element={<Component />} />
        ))}

        <Route path="/auth" element={<AuthPage />} />

        {/* Используем PrivateRoute для защиты страниц */}
        <Route
          path="/"
          element={
            <PrivateRoute>
              {/*{window.innerWidth < 769 ? <HomeMobilePage /> : <HomePage />} */}{/* Executing inline logic */}
              <Suspense fallback={<span className="cnt">Загрузка...</span>}>
                {(isMobile) ? (
                  <HomeMobilePage />
                ) : (
                  <HomePage />
                )}
              </Suspense>
            </PrivateRoute>
          }
        />
        <Route
          path="/profile"
          element={
            <PrivateRoute>
              <ProfilePage />
            </PrivateRoute>
          }
        />
        <Route
          path="/create-task"
          element={
            <PrivateRoute>
              <CreateTaskPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/task/:id"
          element={
            <PrivateRoute>
              <TaskViewPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/task-edit/:id"
          element={
            <PrivateRoute>
              <TaskEditPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/notify"
          element={
            <PrivateRoute>
              <NotifyPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/manage-users"
          element={
            <PrivateRoute>
              <ManageUsersPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/my-todos"
          element={
            <PrivateRoute>
              <MyTodosPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/companies"
          element={
            <PrivateRoute>
              <CompanyPage />
            </PrivateRoute>
          }
        />
      </Routes>
    </Suspense>
  );
}

export default App;