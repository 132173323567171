// src/features/taskSlice.js
import { createSlice } from '@reduxjs/toolkit';

const taskSlice = createSlice({
  name: 'tasks',
  initialState: {
    tasksList: [],
    currentTask: null,
  },
  reducers: {
    setTasks: (state, action) => {
      state.tasksList = action.payload;
    },
    addTask: (state, action) => {
      state.tasksList.push(action.payload);
    },
    setCurrentTask: (state, action) => {
      state.currentTask = action.payload;
    },
    addCommentToTask: (state, action) => {
      const { taskId, comment } = action.payload;
      const task = state.tasksList.find((t) => t.id === taskId);
      if (task) {
        task.comments = [...task.comments, comment];
      }
    },
  },
});

export const { setTasks, addTask, setCurrentTask, addCommentToTask } = taskSlice.actions;
export default taskSlice.reducer;
