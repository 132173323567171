// /src/pages/TaskView/MyTodosPage.js
import React, {useEffect, useState, useRef} from 'react';
import {Link, useNavigate} from "react-router-dom";
import useFetch from "../../utils/useFetch";
import SvgIcon from "../../components/SvgIcon";
import {formatDateDifference} from "../../utils/formatDateToD";
import {isObject} from "../../utils/checkIsObj";

import './MyTodos.css'
import GoToMP from "../../components/GoToMP";

const MyTodosPage = () => {
  const UID=localStorage.getItem('UID')
      , msg='FER_NUID_86. Отсутствуют данные.'
  ;
  if(!UID) {
    alert(msg)
    throw new Error(msg)
  }
  const sp={
        searchParams:'',// поисковое слово
        limit:    '210', // сколько максимум выводить задач при поиске
        state:    '-1', // состояние -1 — все статусы, 0 — удалена, 1 — новая, 2 — в работе, 3 — завершена
        dateFrom: '',  // дата «с» new Date(new Date().setDate(new Date().getDate() - 7)).toISOString().split('T')[0]
        dateTo:   '',  // дата «по» new Date().toISOString().split('T')[0]
        orderBy:  '2', // сортировка 0 — title, 1 — id, 2 — deadline
        creator:  UID,  // создатель
        team:     UID,  // команда
        resp:     UID,  // ответственный
        sort:     '0', // 0-ask|1-desc
        dep:      '',  // отдел
        needComm: '0', // нужны ли комментарии (0—нет,1—да) — добавляет подсчёт комментариев
        needVote: '0', // нужно ли кол-во голосов (0—нет,1—да) — добавляет подсчёт голосов | если «да», то упорядочивается по кол-ву голосов, от > к <
      }
    , { setFetch: getSearch, dataResponse: getSearchData }           = useFetch('todo/getSearch',null)
    , [todos, setTodos] = useState([])

  useEffect(() => {
    getSearch(new URLSearchParams(sp).toString());
  }, []);
  useEffect(() => {
    if(
      getSearchData
      &&isObject(getSearchData)
      &&getSearchData.s===1
      &&getSearchData.d
    ){
      const j=JSON.parse(getSearchData.d)
      setTodos(j);
    }
  }, [getSearchData]);
  return <div className="cnt mobPDx MTS">
      <h1 className="fx fsb ac gap1 my2">
        <span className="fx ac gap">
          <SvgIcon hash="myTodo" width="36"/>
          Мои задачи
        </span>
        <GoToMP/>
      </h1>
      {
        (todos && todos.length>0)?
          <div>
            <ul className="table mb">
              <li className="mobDn">
                <span className="mobDn tc"><strong>Название</strong></span>
                <span className="mobDn tc"><strong>Дедлайн</strong></span>
                <span className="mobDn tc"><strong>Ответственный</strong></span>
              </li>
              {
                todos.map(e => {
                  /**
                   * {
                   *     "id": 256,
                   *     "title": "название задачи",
                   *     "datetime": "2024-10-24 17:48:21",
                   *     "state": 2,
                   *     "UID": 4439,
                   *     "description": "comm",
                   *     "updated": "2024-10-24 17:48:21",
                   *     "deadline": "2024-10-25",
                   *     "resp": 4439,
                   *     "json": "{\"files\":null,\"team\":[4439],\"dep\":7}",
                   *     "resp_name": "John Doe"
                   * }
                   */
                  return (
                    <li className={`${e.state === '0' ? ' NPUnrd' : ''}`} key={e.id}>
                      <span>
                        {
                          {
                            '0': <q className="s_ s0">удалена</q>,
                            '1': <q className="s_ s1">новая</q>,
                            '2': <q className="s_ s2">в работе</q>,
                            '3': <q className="s_ s3">завершена</q>
                          }[String(e.state)] || null
                        }
                        <Link to={`/task/${e.id}`}>{e.title}</Link>
                      </span>
                      <span>{formatDateDifference(e.deadline)}</span>
                      <span>{e.resp_name}</span>
                    </li>
                  );
                })
              }
            </ul>
          </div>
        :
        <h3 className="db w1 tc my2">Задач не найдено</h3>
      }
  </div>
}
export default MyTodosPage;