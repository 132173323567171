// src/pages/Auth/AuthPage.js
import React, {useState, useEffect, useRef} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import useFetch from '../../utils/useFetch';
import './AuthPage.css'; // Импорт CSS-файла

const AuthPage = () => {
  const hashInvite=localStorage.getItem('h')
    , UID=localStorage.getItem('UID')
    , style=localStorage.getItem('style')
    , [phone, setPhone] = useState('+7-')
    , [code, setCode] = useState('––––')
    , [isSubmitEnabled, setIsSubmitEnabled] = useState(false)
    , [nextStep, setNextStep] = useState(false)
    , { setFetch: setCodeFetch } = useFetch('auth/setCode',phone.replace(/\D/g, ''))
    , { setFetch: getCheckCode, dataResponse: getDataCheckCode } = useFetch('auth/getCheckCode', {
        code:code.replace(/\D/g, ''),invite: hashInvite ?? '1'})
    , { setFetch: getToken, dataResponse: getDataToken } = useFetch('auth/getToken', '1')
    // , [count, setCount] = useState(30)
    , [isInitialClick, setIsInitialClick] = useState(true) // Состояние, определяющее текущий обработчик
    , TIMER=300
    , [timeLeft, setTimeLeft] = useState(TIMER) // Начальное время в секундах
    , [isActive, setIsActive] = useState(false) // Индикатор состояния таймера
    , [forTimer, setForTimer] = useState(false)
    , navigate = useNavigate()
    , { setFetch: getCheckAuth, dataResponse: getDataCheckAuth } = useFetch('auth/getCheckAuth','1')
    , { setFetch: setWaMsg, dataResponse: setWaMsgAuth } = useFetch('auth/setWaMsg',null)
    , handleSetWA=()=>{
        if(phone.length!==16){
          alert('Проверьте номер телефона.')
        }
        setWaMsg(phone)
      }
    , inputRef = useRef(null)
  ;

  if(UID){localStorage.removeItem('UID')}
  if(style){localStorage.removeItem('style')}

  // Вызов getToken только один раз при монтировании компонента
  useEffect(() => {
    getToken(); // Выполняем запрос
  }, []);

  useEffect(() => {
    if(phone.length===16){
      inputRef.current.focus(); // Устанавливаем фокус
      inputRef.current.setSelectionRange(0,0); // Перемещаем каретку в конец
    }
  }, [isSubmitEnabled]);

  // Отслеживаем изменения в getDataToken и выводим его, когда данные обновятся
  useEffect(() => {
    if (
      getDataToken
      && getDataToken.s
      && getDataToken.s===1
      && getDataToken.d
      && getDataToken.d!==''
      && !getDataToken.d.startsWith('Вы успешно')
    ) {
      localStorage.setItem('hash',getDataToken.d)
    }
  }, [getDataToken]); // Запускается при изменении getDataToken

  // useEffect(() => {
  //    // Выполняем запрос
  // }, []);
  useEffect(()=>{
    if(getDataCheckAuth){
      // Проверяем, если мы авторизованы и пытаемся перейти на /
      if (getDataCheckAuth?.s===1 && window.location.pathname === '/auth') {
        // const UID=parseInt(getDataCheckAuth?.d);
        // if(UID&&UID>0){
        //   localStorage.setItem('UID',UID)
        // }
        // Переадресовываем на главную страницу
        navigate('/', { replace: true });
      }
    }
  },[getDataCheckAuth])
  const handlePhoneChange = e => {
    const input = e.target.value.replace(/\D/g, ''); // Удаляем все нечисловые символы
    let formattedPhone = '+7';
    // Форматируем по шаблону +7-999-888-77-66
    if (input.length > 1) formattedPhone += '-' + input.slice(1, 4)
    if (input.length >= 5) formattedPhone += '-' + input.slice(4, 7)
    if (input.length >= 8) formattedPhone += '-' + input.slice(7, 9)
    if (input.length >= 10) formattedPhone += '-' + input.slice(9, 11)

    setPhone(formattedPhone);
    const IC=document.querySelector('.lgnCode')
    if(IC && phone.length===16)IC.focus();

    // Проверяем, если номер телефона полностью заполнен
    if (input.length === 11) {
      setIsSubmitEnabled(true); // Кнопка активна
    } else {
      setIsSubmitEnabled(false); // Кнопка неактивна
    }
  };
  const handleCodeInput = e => {
    const numericValue = e.target.value.replace(/\D/g, '');
    if(numericValue.length===4){
      setIsSubmitEnabled(true);
    }
    if(numericValue.length>4){
      return null;
    }
    setCode(numericValue);
  };
  const handleChangeStep = () => {
    setNextStep(nextStep => !nextStep);
    setIsInitialClick(isInitialClick=> !isInitialClick)
    if(phone.length===16){
      setIsSubmitEnabled(true)
    }else{
      setIsSubmitEnabled(false)
    }
  };
  useEffect(()=>{
    if(getDataCheckCode){
      if(getDataCheckCode?.s===0){
        if(getDataCheckCode.d && getDataCheckCode.d.startsWith('Вы уже')){
          navigate('/', { replace: true });
        }
      }
      if(getDataCheckCode?.s===1){
        getCheckAuth();
        const m_s_g='Заполните данные профиля: имя, фамилию, фото, email.'
        if(getDataCheckCode?.d.startsWith('+')){
          alert(m_s_g)
          navigate('/profile', { replace: true });
        }else{
          if(hashInvite){
            alert(m_s_g)
            navigate('/profile', { replace: true });
          }else{
            navigate('/', { replace: true });
          }
        }
        // try {
        //   if(getDataCheckCode?.d.startsWith('[{')){
        //     // Попытка распарсить JSON
        //     const j = JSON.parse(getDataCheckCode?.d);
        //     // Если это массив
        //     if (typeof j === 'object' && j.TID) {
        //       localStorage.removeItem('h');
        //       navigate('/task/'+j.TID, { replace: true });
        //     }
        //   }else{
        //     navigate('/', { replace: true });
        //   }
        // } catch (e) {
        //   // Если парсинг JSON не удался, значит это строка (при авторизации без перенаправления на TID)
        //   navigate('/', { replace: true });
        // }
      }
    }
  },[getDataCheckCode])
  const handleSubmitCheckCode = () => {
    getCheckCode()
  };

  const handleSubmit = () => {
    if (isSubmitEnabled) {
      setIsSubmitEnabled(false)
      setNextStep(true)
      // // Вызов функции setFetch с нужными параметрами
      setCodeFetch()   // Передаем what и data
        .then(response => {
          setIsInitialClick(false)
          // Например, показываем уведомление или перенаправляем пользователя
        })
      setTimeLeft(TIMER);  // Сбрасываем таймер до 30 секунд
      setIsActive(true); // Активируем таймер
    }
  };
  useEffect(() => {
    let timer;
    // Запускаем таймер, если он активен и время больше 0
    if (isActive && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft(prevTime => prevTime - 1);
      }, 1e3);
    }
    // Если время истекло, останавливаем таймер
    if (timeLeft === 0) {
      setForTimer(true);
      setIsActive(false);
    }
    // Очищаем таймер при размонтировании компонента или остановке
    return () => clearInterval(timer);
  }, [isActive, timeLeft]);
  useEffect(() => {
    const style = document.createElement('style');
    style.innerHTML = `
      @media (prefers-color-scheme: dark) {
        body{
          background-color: #1c1c1c;
          color: #c5c5c5;
        }
        input{
          color: #c5c5c5;
        }
        button.lgnSnd[disabled]{
          background-color:#2f2f2f;
        }
        div.alert div.alertD{
            background: 50% 50% / cover url('/style/bg.svg');
            background-color: #2c2c2c;
            box-shadow: 0 6px 31px -6px #242424;
            pre{
                color:#fff;
            }
        }
      }
    `;
    document.head.appendChild(style);

    return () => {
      document.head.removeChild(style); // Убираем стиль при размонтировании компонента
    };
  }, []);


  return (
    <div className="fx ac jc">
      <div className="fx jc fdc gap3 lgn">
        <p className="pr fx ac jc tc lgnHeader">
          <button
            className={`pa fx ac jc fs0 br50 nav${(!nextStep)?' o0':''}`}
            onClick={handleChangeStep}
          >&lt;</button>
          <strong>Авторизация</strong>
        </p>
        <div className="fx py1 px0 clipR ovh">
          <span className={`fx fdc gap1 lgnC lgnCntr${(nextStep)?' lgnCl':''}`}>
            <h1>Введите номер телефона</h1>
            <p><strong>Мы отправим Вам сообщение с 4-х значным кодом.</strong></p>
            <input
              type="tel"
              name="tel"
              value={phone}
              onChange={handlePhoneChange}
              maxLength="16" // Ограничение длины ввода
              placeholder="+7-___-___-__-__"
              className="fwb blk"
            />
          </span>

          <span className={`pa fx fdc gap1 lgnC lgnCntr${(!nextStep) ? ' lgnCl' : ''}`}>
            <h1>Код из смс</h1>
            <p><strong>Мы отправили сообщение <br/> с кодом на номер {phone}</strong></p>
            <input
              type="tel"
              value={code}
              onInput={handleCodeInput}
              ref={inputRef}
              placeholder="––––"
              className="fwb blk tc lgnCode"
            />
          <span className={`cg${forTimer ? ' dn' : ''}`}>
            Ваш код придёт в течение {timeLeft} секунд.
          </span>
          <span className={`lgnHlp${!forTimer ? ' vh' : ''}`}>
            <ul className="fx fdc gap.5">
              <li><strong className="mark">Не приходит сообщение?</strong></li>
              <li onClick={()=>{window.location.reload()}}><strong>Попробовать ещё раз</strong></li>
              <li><button onClick={handleSetWA}><strong>Отправить код на WhatsApp</strong></button></li>
            </ul>
          </span>
          </span>
        </div>
        <button
          className="m0a lgnSnd"
          disabled={!isSubmitEnabled} // Кнопка активируется только, если номер полностью заполнен
          onClick={isInitialClick ? handleSubmit : handleSubmitCheckCode} // Отправка данных
        >Отправить
        </button>
        <span className={`cg${(nextStep)?' vh':''}`}>
          Продолжая, Вы соглашаетесь с <Link to="/tos">условиями использования и политикой конфиденциальности</Link>
        </span>
      </div>
    </div>
  );
};
export default AuthPage;