// src/pages/Profile/ProfilePage.js
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link , useNavigate} from "react-router-dom";
import {setProfileData, updateProfileField, updateProfileFieldJSON} from '../../features/profileSlice';
import useFetch from '../../utils/useFetch';
import GoToMP from "../../components/GoToMP";
import SvgIcon from "../../components/SvgIcon";
import changeStyle from '../../utils/changeStyle';
import settings from "../../utils/settings";
import './Profile.css';

const ProfilePage = () => {

  const { setFetch: getUD, dataResponse: getDataUD } = useFetch('user/getUD','1')
    , dispatch = useDispatch()
    , profile = useSelector((state) => state.profile.data)
    , navigate = useNavigate()
    , [imageUrl,setImgUrl] = useState('')
    , [activeButton, setActiveButton] = useState(null)
    , [hide, setHide] = useState(true)
    , [err, setErr] = useState([])
    , errorsSet=(index)=>{
        setErr(prev=>{
          // Проверяем, есть ли index в массиве prev
          if (!prev.includes(index)) {
            return [...prev, index];
          }
          return prev; // Если index уже есть, возвращаем массив без изменений
        })
      }
    , errorsRemove=(index)=>{
        setErr(prev => {
          // Если index уже есть в массиве, удаляем его
          if (prev.includes(index)) {
            return prev.filter(item => item !== index);
          }
          // Если index нет в массиве, добавляем его
          return [...prev, index];
        });
      }
    , changeCT = (value) => {
        if(!err.includes('save'))errorsSet('save')
        changeStyle(value,false)
        setActiveButton(value); // Обновляем активную кнопку
        dispatch(updateProfileFieldJSON({ field: 'json.ct', value }));
      }
    , { setFetch: delAcc } = useFetch('user/setDelAcc','1')
    , { setFetch: setUserData, dataResponse: setUserDataResp} = useFetch('user/setUserData',profile)
  ;
  useEffect(()=>{
    getUD()
  },[])
  useEffect(()=>{
    if (getDataUD && getDataUD.s === 1) {
      const j=JSON.parse(getDataUD.d);
      dispatch(setProfileData(j));
      if(profile&&profile.id!==''&&profile?.avatar!==''){
        setImgUrl(settings.REACT_APP_API_URL_IMG+profile.id+'/'+profile.avatar+'_t');
      }
    }
  },[getDataUD, dispatch]);
  useEffect(() => {
    /**
     * profile > {
     *     "id": 4439,
     *     "name": "John Doe",
     *     "phone": "+7-949-403-46-27",
     *     "email": "teachstore.org@gmail.com",
     *     "rights": "0", [0 — user,1 — admin,2 — super]
     *     "avatar": "7c5845cea683556d813ebd657e8cca011731947641",
     *     "json": {
     *         "g": "0",
     *         "ct": "3",
     *         "bd": "2020-01-07"
     *     }
     * }
     */
    if (profile && profile.id) {
      if(profile.name==='' && !err.includes('name'))errorsSet('name')
      if(profile.email==='' && !err.includes('email'))errorsSet('email')
      if(profile.avatar==='' && !err.includes('avatar'))errorsSet('avatar')
    }
    if (profile && profile.id && profile.id !== '' && profile.avatar) {
      setImgUrl(settings.REACT_APP_API_URL_IMG + profile.id + '/' + profile.avatar + '_t');
    }
  }, [profile]); // Этот effect будет вызываться, когда profile изменится

  const handleChangeJSON = (e) => {
    const { value } = e.target
        , field = e.target.dataset.t
    ;
    if(!err.includes('save')) errorsSet('save')
    dispatch(updateProfileFieldJSON({ field, value }));
  };
  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (!file) {
      console.log('Выберите файл для загрузки.');
      return;
    }
    try {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('set', 'file/setAvatar');
      formData.append('location_', window.location.href);
      const token = localStorage.getItem('hash');
      if (!token) {
        throw new Error('No token');
      }
      const response = await fetch(settings.REACT_APP_API_URL+'/index.php?fetch=true', {
        method: 'POST',
        body: formData,
        credentials: 'include',
        headers: {
          'Accept': 'application/json, text/plain',
          'X-Token': token,
        },
      });

      const data = await response.json();
      if(data.s===1){
        const j=JSON.parse(data.d);
        if(!j.thb||!j.ori){
          throw new Error('FER_Y545. No data found');
        }
        setImgUrl(settings.REACT_APP_API_URL_IMG+'/'+profile.id+'/'+j.thb)
        dispatch(updateProfileField({ field: 'avatar', value: j.ori }));
        if(!imageUrl)errorsRemove('avatar')
        console.log('Успех:', data);
      }
    } catch (e) {
      console.error('Ошибка:', e);
    }
  };
  const handleDelAcc=()=>{
    const c=window.confirm('Вы действительно хотите удалить свою учётную запись?');
    if(c){
      delAcc();
      navigate('/auth');
      localStorage.removeItem('hash')
    }
  }
  const handleChangeData=e=>{
    const { name, value } = e.target;
    if(!err.includes('save'))errorsSet('save')
    if(name==='name'){
      if(err.includes('name')) {
        errorsRemove('name')
      }
    }
    if(name==='email'){
      if(err.includes('email')) {
        errorsRemove('email')
      }
    }
    dispatch(updateProfileField({ field: name, value }));
  }
  useEffect(() => {
    if (setUserDataResp) {
      alert(setUserDataResp.d);
      if(setUserDataResp.s===1 && setUserDataResp.d.startsWith('Данные у')){
        setHide(false)
      }
    }
  }, [setUserDataResp]);

  const handleSaveData=()=>{
    setUserData()
    errorsRemove('save')
  }

  useEffect(() => {
    if(err){
      if((profile && (profile.name==='' || profile.email==='' || profile.avatar==='')) && err.length===1 && err.includes('save')){
        setHide(false)
      }
      if(profile && profile.name!=='' && profile.email!=='' && profile.avatar!==''){
        setHide(false)
      }
    }
  }, [err,profile]);

  return (
    <div className="cnt pfl mobPDx">
      <h1 className="fx ac fsb">
        <span className="fx ac gap">
          <SvgIcon hash="userProfile" width="36"/>
          Личный кабинет
        </span>
        <GoToMP/>
      </h1>
      <div className="pr z2">
        <hr/>
        {profile.id ? (
          <>
            {
              err.includes('name') &&
              <mark className="fx ac gap my.5 w1/3"><SvgIcon hash="info"/> Необходимо ввести имя.</mark>
            }
            {
              err.includes('email') &&
              <mark className="fx ac gap my.5 w1/3"><SvgIcon hash="info"/> Необходимо ввести email.</mark>
            }
            {
              err.includes('avatar') &&
              <mark className="fx ac gap my.5 w1/3"><SvgIcon hash="info"/> Необходимо загрузить фото.</mark>
            }
            {
              err.includes('save') &&
              <mark className="fx ac gap my.5 w1/3"><SvgIcon hash="info"/> Необходимо нажать кнопку «сохранить».</mark>
            }
            <div className="fx fsb mt mobFdc mobGap">
              <div className="fx fdc">
                <span className="pr fx mb pflPcr">
                  {(profile.avatar !== '') ?
                    (
                      <span className="fx ac gap1">
                        <picture>
                          {/*<source srcSet={`${imageUrl}.avif`} type="image/avif"/>*/}
                          <source srcSet={`${imageUrl}.webp`} type="image/webp"/>
                          <img
                            src={`${imageUrl}.jpg`}
                            alt="avatar"
                            width="62"
                            className="br50 ava"
                          />
                        </picture>
                        {(()=>{
                          if(profile?.rights && parseInt(profile?.rights) > 0){
                            let role='администратор';
                            if(profile?.rights==="2")role=<span>суперадминистратор<br/>Вы можете управлять <Link to="/companies">компаниями</Link>.</span>
                            return <span><strong>Ваша роль:</strong> {role}</span>
                          }
                        })()}
                      </span>
                    ) :
                    <div className="ava avaNS">&nbsp;</div>
                  }

                  <label className={`pa cp addFile${(profile.avatar === '') ? ' addFileOp1' : ''}`}>
                      <SvgIcon hash="pic-up" width="20" class_="br50"/>
                      <input
                        className="file"
                        type="file" accept="image/png, image/jpeg, image/webp"
                        onChange={handleFileChange}
                      />
                  </label>
                </span>
                <span className="pr fx mx">
                  <input
                    className="fs ia inp"
                    id="name" name="name" required
                    defaultValue={profile.name}
                    onChange={handleChangeData}
                  />
                  <label className="intLb" htmlFor="name">Имя, фамилия</label>
                </span>
                <span className="pr fx mx">
                  <input
                    className="fs ia inp"
                    id="email" required name="email"
                    defaultValue={profile.email}
                    onChange={handleChangeData}
                  />
                  <label className="intLb" htmlFor="email">Email</label>
                </span>
                <span className="pr fx mx">
                  <input
                    className="fs ia inp"
                    id="bd" required name="birthdy"
                    type="date"
                    data-t="json.bd"
                    defaultValue={profile?.json?.bd}
                    onChange={handleChangeJSON}
                  />
                  <label className="intLb" htmlFor="bd">Дата рождения</label>
                </span>
                <span className="fx gap3">
                  <label className="radio-label">
                    <input
                      type="radio"
                      name="gender"
                      value="1"
                      data-t="json.g"
                      checked={profile?.json?.g === '1'}
                      onChange={handleChangeJSON}
                    />
                    <span className="custom-radio"></span>
                    Мужчина
                  </label>
                  <label className="radio-label">
                    <input
                      type="radio"
                      name="gender"
                      value="0"
                      data-t="json.g"
                      checked={profile?.json?.g === '0'}
                      onChange={handleChangeJSON}
                    />
                    <span className="custom-radio"></span>
                    Женщина
                  </label>
                </span>
              </div>
              <div className="fx fdc fsb mobGap3 mobGridDelAcc">
                <span className="fx fdc">
                  <p>Цвет фона:</p>
                  <span className="fx gap1 mt pflChangeCT">
                    <button
                      className={(activeButton === '0' ? 'pflBtnAct' : '') + ' pflBtn fs0 br50'}
                      onClick={() => changeCT('0')}
                    >0</button>
                    <button
                      className={(activeButton === '1' ? 'pflBtnAct' : '') + ' pflBtn fs0 br50'}
                      onClick={() => changeCT('1')}
                    >1</button>
                    <button
                      className={(activeButton === '2' ? 'pflBtnAct' : '') + ' pflBtn fs0 br50'}
                      onClick={() => changeCT('2')}
                    >2</button>
                    <button
                      className={(activeButton === '3' ? 'pflBtnAct' : '') + ' pflBtn fs0 br50'}
                      onClick={() => changeCT('3')}
                    >3</button>
                  </span>
                </span>
                <span className="fx ac gap">
                  <SvgIcon hash="basket" width="36" class_="br50"/>
                  <button
                    className="pflBtnDelAcc"
                    onClick={handleDelAcc}
                  >Удалить аккаунт
                  </button>
                </span>
              </div>
            </div>
            <button
              className="mt2 fwb pflSave"
              onClick={handleSaveData}
              disabled={err && err.length !== 1}
            >Сохранить
            </button>
          </>
        ) : (
          <p className="cnt mobPDx">Загрузка данных...</p>
        )}
      </div>
      {
        hide && <div className="pf hide"></div>
      }
    </div>
  );
};

export default ProfilePage;