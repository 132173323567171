export function formatDateDifference(
  dateString,
  plus=false
) {
  if(dateString==='0000-00-00')return '∞';
  const targetDate = new Date(dateString)
    , currentDate = new Date()

  // Разница в миллисекундах
    , diffTime = targetDate - currentDate

  // Переводим разницу в дни и часы
    , diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24))
    , diffHours = Math.floor((diffTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
  ;
  if (diffTime >= 0) { // Если разница положительная (будущее)
    if (Math.abs(diffDays) >= 1) {
      return `${diffDays}д`;
    } else {
      return `${diffHours}ч`;
    }
  } else { // Если разница отрицательная (прошлое)
    const absDays = diffDays
        , absHours = diffHours
    ;
    if (Math.abs(absDays) > 1) {
      return `${(plus)?Math.abs(absDays):absDays}д`;
    } else {
      return `${(plus)?Math.abs(absHours):absHours}ч`;
    }
  }
}

export function formatDateToWordForm(date, showTime = false) {
  date = new Date(date);

  // Опции для форматирования даты
  const options = { day: '2-digit', month: 'short', weekday: 'short' };
  let formattedDate = date.toLocaleDateString('ru-RU', options).replace('.', '');

  if (showTime) {
    // Получаем часы и минуты
    const time = date.toLocaleTimeString('ru-RU', {
      hour: '2-digit',
      minute: '2-digit',
    });
    formattedDate += `, ${time}`; // Добавляем время к строке даты
  }

  return formattedDate;
}