// src/pages/TOSPage.js
import React from 'react';

const TOSPage = () => {
  return (
    <div style={{ padding: '20px' }}>
      <h1>Terms of Service</h1>
      <p>Welcome to our Terms of Service page.</p>
      <p>Here you can put the terms and conditions for using the application.</p>
      <h2>1. Agreement to Terms</h2>
      <p>By accessing or using our application, you agree to be bound by these Terms of Service.</p>
      {/* Добавьте больше разделов и условий по необходимости */}
    </div>
  );
};

export default TOSPage;