// src/store/notificationSlice.js
import { createSlice } from '@reduxjs/toolkit';

const notificationSlice = createSlice({
  name: 'notification',
  initialState: {
    notification: false, // по умолчанию уведомлений нет
  },
  reducers: {
    setNotification: (state, action) => {
      state.notification = action.payload;
    },
  },
});

// Экспортируем actions и reducer
export const { setNotification } = notificationSlice.actions;
export default notificationSlice.reducer;