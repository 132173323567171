import React from "react";

const InputFile=({handleFileChange=()=>{}})=> {
  return <input
    className="file" multiple
    type="file" accept="
      image/jpeg, image/png, image/gif, image/webp,
      application/pdf,
      application/msword,
      application/vnd.openxmlformats-officedocument.wordprocessingml.document,
      application/vnd.oasis.opendocument.text,
      application/vnd.ms-powerpoint,
      application/vnd.openxmlformats-officedocument.presentationml.presentation,
      application/vnd.ms-excel,
      application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
      text/plain,
      application/rtf,
      application/vnd.oasis.opendocument.spreadsheet,
      application/vnd.oasis.opendocument.presentation,
      application/x-7z-compressed,
      application/zip,
      application/x-rar-compressed,
      application/x-tar
    "
    onChange={handleFileChange}
  />
}
export default InputFile