// src/utils/useFetch.js
import { useState } from 'react';
import settings from "./settings";

const useFetch = (what, data, fn = () => {}) => {
  // if(!what||!data){
  //   throw new Error('FER_U7Y. No "data" and "what".')
  // }
  const [loading, setLoading] = useState(false)
      , [error, setError] = useState(null)
      , [dataResponse, setDataResponse] = useState(null)
      // , [prevErr, setPrevErr] = useState('')
  ;
  const setFetch = async (dynamicData=null) => {
    try {
      setLoading(true);
      // setError(null);
      if(dynamicData!==null)data=dynamicData

      const tkn = localStorage.getItem('hash');
      let searchStr = '';

      if (typeof data === 'string' && data.startsWith('searchParams')) {
        searchStr = '&' + data;
      }

      if (what !== 'auth/getToken' && !tkn) throw new Error('FE_YU654. No token');

      let path = '';
      if (what.includes('/')) {
        const vec01 = what.split('/');
        if (!vec01[1]) throw new Error('FER_8876HG. No data in [1]');
        if (vec01[1].startsWith('get')) {
          path = 'get';
        } else if (vec01[1].startsWith('set')) {
          path = 'set';
        } else {
          throw new Error('FER_784_8. No data in path');
        }
      }

      const response = await fetch(`${settings.REACT_APP_API_URL}/index.php?fetch=true${searchStr}`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json, text/plain',
          'X-Token': tkn,
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          location_: window.location.href,
          [path]: what,
          data,
        }),
      });

      if (!response.ok) {
        const rr = await response.json();
        setDataResponse(rr)
        let msg = '';
        if (rr.d) msg = rr.d;
        if (typeof fn === 'function') {
          fn(rr);
        }
        throw new Error(`FER_098JHTQ_343. HTTP error! Status: ${response.status}\n${msg}`);
      }

      const r = await response.json();
      if (!r) {
        throw new Error('FER_YY5454. No data found.');
      }
      if (r.s === 0) {
        if(!r.d.startsWith('Комментариев не')) {
          // if(prevErr!==r.d){
          //   setPrevErr(r.d)
            alert(r.d);
          // }
        }
        throw new Error('FER_YYTG3_4545. No data found.');
      }
      if (r.s === 1) {
        setDataResponse(r)
        if (!r.d) {
          throw new Error('FER_YYTG3_67676. No data found.');
        }
        if (r.d.startsWith('[{')) { // Get json data
          const json = JSON.parse(r.d);
          if (typeof fn === 'function') {
            fn(json);
          }
        }
        // Handle style injection
        const d = document;
        if (r.c && r.hc) {
          const st = d.createElement('style');
          st.textContent = r.c;
          d.body.appendChild(st);
        }
        // Execute script if exists
        // if (r.j) {
        //   new Function(r.j)();
        // }
      }
    } catch (e) {
      setError('FE_45634_EWC. Ошибка... Обновите страницу и попробуйте снова. ' + e.stack);
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  return { setFetch, dataResponse, loading, error };
};

export default useFetch;