import React, { useState, useEffect, useRef } from 'react';
import SvgIcon from "./SvgIcon";
import './Search.css'
import useFetch from "../utils/useFetch";
const SearchBar = ({cb, type=''}) => {
  const [btn,setBtn]=useState(0)
      , [word, setWord]=useState(0)
      , ref = useRef(null) // Ссылка на элемент
      , ref2 = useRef(null) // Ссылка на элемент
      , searchParams={
        searchParams:word,// поисковое слово
        limit:    '50',// сколько максимум выводить задач при поиске
        state:    '1', // состояние 0 — удалена, 1 — новая, 2 — в работе, 3 — завершена
        dateFrom: '',  // дата «с» new Date(new Date().setDate(new Date().getDate() - 7)).toISOString().split('T')[0]
        dateTo:   '',  // дата «по» new Date().toISOString().split('T')[0]
        orderBy:  '1', // 0-ask|1-desc
        creator:  '',  // создатель
        team:     '',  // команда
        resp:     '',  // ответственный
        sort:     '1', // сортировка 0 — title, 1 — id, 2 — deadline
        dep:      '',  // отдел
        needComm: '0', // нужны ли комментарии (0—нет,1—да) — добавляет подсчёт комментариев
        needVote: '1', // нужно ли кол-во голосов (0—нет,1—да) — добавляет подсчёт голосов | если «да», то упорядочивается по кол-ву голосов, от > к <
      }
      , { setFetch: getSearch, dataResponse: getSearchData } = useFetch('todo/getSearch',null,e=>{(e&&e?.s===0) && alert(e.d)} )
      , handleClick = () => {
          if(btn===0)setBtn(1)
          if(btn===1&&word.length>1)getSearch(new URLSearchParams(searchParams).toString())
        }
      , handleInput=e=>{
          setWord(e.target.value)
        }
  if(type==='completed'){
    searchParams.needVote='0';
    searchParams.state='3';
  }
  if(type==='all'){
    searchParams.needVote='0';
    searchParams.state=-1;
  }

  useEffect(() => {
    if(getSearchData && getSearchData.s===1){
      if(getSearchData.d)cb(JSON.parse(getSearchData.d))
    }
  }, [getSearchData]);


  useEffect(() => {
    // Функция, которая будет вызвана при клике на документ
    const handleClickOutside = (event) => {
      // Проверяем, был ли клик вне элемента с ref
      if (
          ref.current
          && !ref.current.contains(event.target)
          && ref.current
          && !ref2.current.contains(event.target)
      ) {
        setBtn(0) // Закрываем
      }
    };

    // Добавляем слушатель события клика на весь документ
    document.addEventListener('click', handleClickOutside);

    // Убираем слушатель при размонтировании компонента
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []); // Массив зависимостей пуст, чтобы использовать эффект только при монтировании компонента


  return <span className={`pr fx ac hSear${btn===1?' hSearCl':''}`}>
      <input
        className="bgf bx"
        placeholder="Поиск"
        onInput={handleInput}
        ref={ref}
        required
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.code === 'Enter') {
            e.preventDefault();
            handleClick(e); // Вызов функции, связанной с кнопкой
          }
        }}
      />
      <button
        className="pa btn br50"
        onClick={handleClick}
        ref={ref2}
      >
        <SvgIcon hash="search" width="24"/>
      </button>
    </span>
}
export default SearchBar