// src/pages/Home/HomePage.js
import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {setDepartments} from '../../features/departmentSlice';
import useFetch from "../../utils/useFetch";
import {formatDateDifference} from "../../utils/formatDateToD";
import SvgIcon from "../../components/SvgIcon";
import {Link} from "react-router-dom";
import {isObject} from "../../utils/checkIsObj";
import SearchBar from "../../components/SearchBar";

import './Home.css'

const HomePage = () => {
  let prevErr=''
  const dispatch = useDispatch()
    , departments = useSelector((state) => state.departments.departmentsList)
    // , [isVoted, setIsVoted] = useState(false)
    , [isNew, setIsNew] = useState(false)
    // , [jsonTodo, setJsonTodo] = useState(0)
    , [isCompl, setIsCompl] = useState(false)
    // , [isVotedF, setIsVotedF] = useState(false)
    , [activeDepartmentId, setActiveDepartmentId] = useState(null)
    , [idDeleted, setIdDeleted] = useState(0)
    , [idVote, setIdVote] = useState(0)
    , [inWork, setInWork] = useState(0)
    , [todos8, setTodos8] = useState([]) // To_do, которые в работе
    , [todosNew, setTodosNew] = useState([])
    , [todosCompleted, setTodosCompleted] = useState([])
    , [filteredTasks, setFilteredTasks] = useState([])
    , err=(e)=> {
        prevErr=e
        if(prevErr!==e){
          alert(e);
        }
      }
    , [searchParams, setSearchParams] = useState({
        searchParams:'',// поисковое слово
        limit:    '50', // сколько максимум выводить задач при поиске
        state:    '2', // состояние 0 — удалена, 1 — новая, 2 — в работе, 3 — завершена
        dateFrom: '',  // дата «с» new Date(new Date().setDate(new Date().getDate() - 7)).toISOString().split('T')[0]
        dateTo:   '',  // дата «по» new Date().toISOString().split('T')[0]
        orderBy:  '1', // 0-ask|1-desc
        creator:  '',  // создатель
        team:     '',  // команда
        resp:     '',  // ответственный
        sort:     '1', // сортировка 0 — title, 1 — id, 2 — deadline
        dep:      '',  // отдел
        needComm: '1', // нужны ли комментарии (0—нет,1—да) — добавляет подсчёт комментариев
        needVote: '0', // нужно ли кол-во голосов (0—нет,1—да) — добавляет подсчёт голосов | если «да», то упорядочивается по кол-ву голосов, от > к <
      })
    , { setFetch: getDepartments, dataResponse: getDepartmentsData } = useFetch('todo/getDepartments','2')
    , { setFetch: getSearch8, dataResponse: getSearch8Data }           = useFetch('todo/getSearch',new URLSearchParams(searchParams).toString(),(e)=>err(e.d) )
    , { setFetch: getSearchNew, dataResponse: getSearchNewData }           = useFetch('todo/getSearch',new URLSearchParams(searchParams).toString(),(e)=>err(e.d) )
    , { setFetch: getSearchCompleted, dataResponse: getSearchCompletedData }= useFetch('todo/getSearch',new URLSearchParams(searchParams).toString(),(e)=>err(e.d) )
    , { setFetch: setVote, dataResponse: setVotedData }= useFetch('todo/setVote', null )
    , { setFetch: setStateTodoById, dataResponse: setStateTodoByIdData }= useFetch('todo/setStateTodoById', null ) // Delete to_do
    , { setFetch: getWhatIVote, dataResponse: getWhatIVoteData }= useFetch('todo/getWhatIVote', '1' )
    , departmentsWithAll = [{ id: -1, title: "Все", count:todos8.length }, ...departments]
    , UID=localStorage.getItem('UID') ?? 0
    , handleDepClickInner = (departmentId) => {
        // Если ID -1, сбрасываем фильтр
        if (departmentId === -1) {
          setFilteredTasks(todos8); // tasks — это полный массив задач
          setInWork(todos8.length)
        } else {
          const filtered = todos8.filter((task) => {
            const jsonParsed = task.json;
            return jsonParsed.dep === departmentId;
          });
          if(filtered.length>0) {
            setInWork(filtered.length)
            setFilteredTasks(filtered)
          }
        }
        setActiveDepartmentId(departmentId); // Активируем текущую кнопку
      }
    , handleDepClick = depId => {
        setActiveDepartmentId(depId);
        handleDepClickInner(depId)
      }
    , handleSetVote=(id)=>{
        const el=document.querySelectorAll('span.table-row.todoNewCl')
        if(el && el.length>0){
          el.forEach(q=>{
            q.classList.remove('todoNewCl')
          })
        }
        setVote(id)
        setIdVote(id)
        const newEl=document.querySelector(`[data-i="${id}"]`)
        if(newEl)newEl.classList.add('todoNewCl')
      }
    , handleSetDeleteTodo=(id)=>{
        setStateTodoById([id,0])
        setIdDeleted(id)
      }
  ;
  // Отделы
  useEffect(() => {
    // Вызываем setNewDepartment для запроса данных об отделах с сервера
    getDepartments();
    getWhatIVote();
  }, []);
  useEffect(() => {
    // Когда ответ получен, обновляем Redux
    if (getDepartmentsData) {
      const dep=JSON.parse(getDepartmentsData.d)
      dispatch(setDepartments(dep));
      setActiveDepartmentId(-1)
    }
  }, [dispatch, getDepartmentsData]);
  // \ Отделы
  // 8
  useEffect(() => {
    getSearch8();
  }, []);
  useEffect(() => {
    if(getSearch8Data&&getSearch8Data.s){
      if (getSearch8Data.s===1&&getSearch8Data.d.startsWith('[{')) {
        const j =JSON.parse(getSearch8Data.d)
        if(j.length>0){
          setInWork(j.length)
          j.forEach(e=>{
            e.json=JSON.parse(e.json)
          })
          setTodos8(j)
        }
      }
    }
  }, [getSearch8Data]);
  useEffect(() => {
    setFilteredTasks(todos8)
  }, [todos8]);
  useEffect(() => {
    if(getWhatIVoteData && getWhatIVoteData.s===1 && getWhatIVoteData?.d.length>0){
      const j =JSON.parse(getWhatIVoteData.d);
      if(j && j.length>0){
        const it=setInterval(()=>{
          const el=document.querySelectorAll("[data-i]")
          if(el && el.length>0){
            clearInterval(it)
            el.forEach(z=>{
              const dataI = Math.floor(z.dataset.i);
              if (j.includes(dataI)) {
                z.classList.add('todoNewCl');
                z.title="Вы проголосовали за эту задачу"
              }
            })
          }
        },1e3)
      }
    }
  }, [getWhatIVoteData]);
  useEffect(() => {
    if(!isNew){
      setSearchParams(prevParams => ({ // устанавливаем параметры поискадля получения новых задач (с подсчётом голосов)
        ...prevParams,
        state: '1', // Новые
        limit: '50', // Новый limit
        needComm: '0', // отмена подсчёта кол-ва комм.
        needVote: '1', // добавим подсчёт голосов и упорядочим по ним
      }));
    }
    if(searchParams.state==='1'&&!getSearchNewData&&!isNew){
      setIsNew(true)
      getSearchNew()
    }
  }, [searchParams,getSearchNewData]);
  useEffect(() => {
    if(!isCompl){
      setSearchParams(prevParams => ({ // устанавливаем параметры поискадля получения новых задач (с подсчётом голосов)
        ...prevParams,
        state: '3', // Заверш.
        limit: '50',
        needComm: '0', // отмена подсчёта кол-ва комм.
        needVote: '0', // добавим подсчёт голосов и упорядочим по ним
      }));
    }
    if(searchParams.state==='3'&&!getSearchCompletedData&&!isCompl){
      setIsCompl(true);
      getSearchCompleted()
    }
  }, [searchParams,getSearchCompletedData]);
  // \ 8
  // Новые
  useEffect(() => {
    if(getSearchNewData&&isObject(getSearchNewData)&&getSearchNewData.s){
      // Получаем новые
      if (getSearchNewData.s===1&&getSearchNewData.d.startsWith('[{')) {
        setTodosNew(JSON.parse(getSearchNewData.d))
      }
      // \ Получаем новые.
    }
  }, [getSearchNewData]);
  // \ Новые
  // завершённые
  useEffect(() => {
    // Получаем завершённые
    if(getSearchCompletedData&&isObject(getSearchCompletedData)&&getSearchCompletedData.s){
      if(getSearchCompletedData&&getSearchCompletedData.s===1&&getSearchCompletedData.d.startsWith('[{')){
        setTodosCompleted(JSON.parse(getSearchCompletedData.d))
      }
    }
    // \ Получаем завершённые
  }, [getSearchCompletedData]);
  // \ завершённые
  useEffect(() => {
    if(setVotedData){
      setSearchParams(prevParams => ({ // устанавливаем параметры поискадля получения новых задач (с подсчётом голосов)
        ...prevParams,
        state: '1', // Новые
        limit: '50', // Новый limit
        needComm: '0', // отмена подсчёта кол-ва комм.
        needVote: '1', // добавим подсчёт голосов и упорядочим по ним
      }));
      getSearchNew();
      // if(setVotedData.s===1 && setVotedData?.d.startsWith('OK_UH'))alert(setVotedData?.d);
      if(setVotedData.s===0 && setVotedData?.d)alert(setVotedData?.d);
    }
  }, [idVote,setVotedData]);
  useEffect(() => {
    if(
        setStateTodoByIdData
        &&isObject(setStateTodoByIdData)
        &&setStateTodoByIdData.s===1
        &&idDeleted
    ){
      setTodosCompleted((prevTodos) => prevTodos.filter((todo) => todo.id !== idDeleted));
      setIdDeleted(0)
    }
  }, [setStateTodoByIdData,idDeleted]);
  return (
    <div className="cnt1280 HPD">{/*HPD — home page desktop*/}
      {
        (Array.isArray(todos8) && todos8.length>0 && departmentsWithAll) ? <ul className="fx gap.4 HPul">
          {
            Array.isArray(departmentsWithAll) && departmentsWithAll.map((department) => (
              <li key={department.id}>
                <button
                  className={`pr fwb btnDep btnDepWC ${activeDepartmentId === department.id ? 'btnDepCl' : ''}${department.id === -1 ? ' btnDepAll' : ''}`}
                  onClick={() => handleDepClick(department.id)}
                >{department.title}{(department.count > 0) ?
                  <small className="pr br50 bx fwb">{department.count}</small> : ''}
                </button>
              </li>
            ))
          }
        </ul> : ''
      }
      <span className="fx fsb">
        {(Array.isArray(todos8) ? <h2 className="mt2 fwb">В работе {inWork}:</h2> : '')}
        <Link to="create-task" className="fx ac gap c0 tu">
          <SvgIcon hash="addTodo" width="26"/>
          создать задачу
        </Link>
      </span>
      <section className="fx gap10p my2">
        <div className="fx fw gap10p w1 ova hpGd">
          {
            (filteredTasks.length !== 0) ? (filteredTasks.map(e => (
              /**
               * {
               *     "id": 264,
               *     "title": "yththth",
               *     "datetime": "2024-11-18 18:27:05",
               *     "state": 1,
               *     "UID": 4439,
               *     "description": "sfgsfgfgdfgdfggfdg",
               *     "updated": "2024-11-18 18:27:05",
               *     "deadline": "2024-11-30",
               *     "resp": 4439,
               *     "resp_name": "Вася Пупкин" (имя ответственного)
               *     "json": "{\"files\":[[\"20241118_031157_126_673b5cc11eaad.jpg\",\"902.92 KB\"],[\"20241118_031157_128_673b5cc11f273.zip\",\"2.59 MB\"],[\"20241118_031157_130_673b5cc11fb2b.zip\",\"984.86 KB\"]],\"team\":[4438],\"dep\":2,\"tmp_tid\":\"tWIbySuJ1cvp\"}"
               * }
               * если needComm=1, то в ответе прилетает также `"comm":5`
               */
              <div key={e.id} className="pr fx fdc fsb br bx hpGdd">
                <Link to={`/task/${e.id}`} className="pa btn fs0" title="Перейти к задаче">Перейти к задаче
                  <SvgIcon hash="arrowRight" width="36"/>
                </Link>
                <h3>{e.title}</h3>
                <p>{e.description || '…'}</p>
                <span className="fx fsb gap.4 pd hpGdSpn">
                  <h4 className="fwb">
                    {e.resp_name || <small className="cg">нет ответственного</small>}
                    <br/><small className="fwn">
                        {(() => {
                          const depId = e.json?.dep
                          return ((depId && depId > 0) ? departmentsWithAll.find(dep => dep.id === depId)?.title : '') || 'нет отдела'
                        })()}
                      </small>
                  </h4>
                  <span className="fx ac gap1">
                    {(e.comm && e.comm > 0) ?
                      <span className="fx ac gap.2 br hpGdSpnBg"><SvgIcon hash="message" width="22"/> {e.comm}</span>
                      :
                      <span>&nbsp;</span>
                    }
                    <span className="fx gap.4 fdc fs12">
                      <span className="fx gap.4 fsb wsnw">в работе <q>{formatDateDifference(new Date(e.json?.inWorkDate??e.json?.datetime), true)}</q></span>
                      <span className="fx gap.4 fsb wsnw">дедлайн <q>
                        {(()=>{
                          const dd=formatDateDifference(e.deadline)
                          if(parseInt(dd)>1)
                              {return dd}
                            else
                              {return <span className="mark">{dd}</span>}
                        })()}
                      </q></span>
                    </span>
                  </span>
                </span>
              </div>
            ))) : <h1 className="w1 tc cg">Задач пока нет...</h1>
          }
        </div>
      </section>
      <section className="fx gap1 hpTL">
        {/*Новые*/}
        <div className="fx fdc w1/2 br ovh hpTLD">
          <span className="fx fsb ac pd hpTLS">
            <span className="fx gap1">
              <h3 className="fwb">Новые</h3>
              {/*<button className="btn" title="Обновить" onClick={()=>handleUpdate('1')}><SvgIcon hash="update"/> </button>*/}
            </span>
            <SearchBar cb={setTodosNew}/>
          </span>
          {
            (todosNew && todosNew.length > 0) ?
              /**
               * {\"id\":246,\"title\":\"Название\",\"datetime\":\"2024-10-16 11:42:12\",\"UID\":4439,\"deadline\":\"2024-10-25\",\"vote\":1}
               */
            <div className={`table`}>
              <span className="table-row">
                <span className="fwb vam table-cell">Название</span>
                <span className="fwb vam table-cell">Дедлайн</span>
                <span className="fwb vam table-cell">Голосов</span>
                <span className="fwb table-cell" title="Проголосовать"><SvgIcon hash="vote" width="26"/></span>
              </span>
              {
                todosNew.map(e => (
                  <span className="table-row" data-i={e.id} key={e.id}>
                    <span>
                      <Link to={`/task/${e.id}`}>
                        {e.title}
                      </Link>
                    </span>
                    <span>{formatDateDifference(e.datetime,true)}</span>
                    <span>{e.vote}</span>
                    <span>
                      <button
                        onClick={() => handleSetVote(e.id)}
                      >
                        <SvgIcon hash="finger" class_="finger" width="16"/>
                      </button>
                    </span>
                  </span>
                ))
              }
            </div> : <h2 className="pd mt2 tc fwb">Данные отсутствуют</h2>
          }
        </div>
        {/*Завершённые*/}
        {/**{"id":264,"title":"yththth","datetime":"2024-11-18 18:27:05","state":3,"UID":4439,"description":"sfgsfgfgdfgdfggfdg","updated":"2024-11-18 18:27:05","deadline":"2024-11-30","resp":4439,"compledUserId":4430,"compledUserName":"John Connor","resp_name":"John Doe"}*/}
        <div className="fx fdc w1/2 br ovh hpTLD hpTLDCl">
          <span className="fx fsb ac pd hpTLS">
            <span className="fx ax gap1">
              <h3 className="fwb">Завершённые</h3>
              {/*<button className="btn" title="Обновить" onClick={() => handleUpdate('3')}><SvgIcon
                hash="update"/> </button>*/}
            </span>
            <SearchBar cb={setTodosCompleted} type="completed"/>
          </span>
          {
            (todosCompleted && todosCompleted.length > 0) ?
              <div className="table">
              <span className="table-row">
                <span className="w1 wb fwb">Название</span>
                <span className="fwb">Завершена</span>
                <span className="fwb">Выполнил</span>
                <span className="fwb">Удалить</span>
              </span>
                {
                  todosCompleted.map(e => (
                    <span className="table-row" key={e.id}>
                  <span>
                      <Link to={`/task/${e.id}`}>
                        {e.title}
                      </Link>
                  </span>
                  <span>{formatDateDifference(e.updated, true)}</span>
                  <span>{e.compledUserName || '—'}</span>
                  <span>
                    {
                      (Math.floor(UID) === e.UID)
                        ? <button
                          onClick={() => handleSetDeleteTodo(e.id)}
                        >
                          <SvgIcon hash="remove" class_="remove" width="16"/>
                        </button> : '—'
                    }
                  </span>
                </span>
                  ))
                }
              </div> : <h2 className="pd mt2 tc fwb">Данные отсутствуют</h2>
          }


        </div>
      </section>
      <div className="mb">&nbsp;</div>
    </div>
  );
};

export default HomePage;