import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';
import useFetch from "../utils/useFetch";
import SvgIcon from "./SvgIcon";
// import settings from "../utils/settings";

const Header = () => {
  const [btn, setBtn] = useState(true)
    , [notify, setNotify] = useState(false)
    , [isAdmin, setIsAdmin] = useState(false)
    , [isSuper, setIsSuper] = useState(false)
    , { setFetch: setLogout } = useFetch('user/setLogout','1')
    , { setFetch: getNotifyCount, dataResponse: getNotifyCountData} = useFetch('notify/getNotifyCount','1')
    , { setFetch: getCheckRights, dataResponse: getCheckRightsData} = useFetch('user/getCheckRights','1')
    , buttonRef = useRef(null)
    , handleClick = () => setBtn((prevBtn) => !prevBtn)
    , navigate = useNavigate()
    , handleClickBtnExit = () => {
        setLogout()
        const vecLS=['hash','UID'];
        vecLS.forEach(e=>{
          localStorage.removeItem(e)
        })
        navigate('/auth')
      }
    /*, handleSearch = () => {
        const s=document.querySelector('.SRCH')
        if(s)s.classList.remove('dn')
      }*/
  ;
  useEffect(() => {
    setInterval(()=>{
      getNotifyCount()
    },1000*60*2)//1000*60*2
  }, []);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      // Проверяем, что клик был не по кнопке --или меню--
      if (
        // menuRef.current &&
        // !menuRef.current.contains(event.target) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target)
      ) {
        setBtn(true);
      }
    };
    if (!btn) {
      document.addEventListener('click', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [btn]);
  useEffect(() => {
    if(
      getNotifyCountData
      && getNotifyCountData.s===1
    ){
      const dt=parseInt(getNotifyCountData.d);
      if(dt>0){
        setNotify(true)
      }
      if(dt===0){
        setNotify(false)
      }
    }
  }, [getNotifyCountData]);

  useEffect(() => {
    getCheckRights()
  }, []);

  useEffect(() => {
    if(getCheckRightsData && getCheckRightsData.s===1){
      const r=parseInt(getCheckRightsData.d);
      setIsAdmin(r > 0)
      setIsSuper(r === 2)
    }
  }, [getCheckRightsData]);

  return (
    <header className="cnt mobPDx fx ac fsb">
      {/*<Link className="fx ac gap.4 logo" to="/">
        <SvgIcon hash="todo" width="38" /><span className="mobDn">{settings.REACT_APP_APP_NAME}</span>
      </Link>*/}
      <span>&nbsp;</span>

      {/*<span className="pr fx ac hSear">
        <input
          className="bgf bx"
          placeholder="Поиск"
          onFocus={handleSearch}
        />
        <button
          className="pa btn br50"
          onClick={handleSearch}
        >
          <SvgIcon hash="search" width="24" />
        </button>
      </span>*/}

      <span className={`pr hBtn ${btn ? 'hBtnCl' : ''}`}>
        <button
          ref={buttonRef}
          className={`pr btn pd0${(notify) ? ' hNtfCl':''}`}
          onClick={handleClick}
        >
          <SvgIcon hash="user" width="38" />
        </button>
        {!btn && (
          <ul /*ref={menuRef}*/ className="pa z4 fx fdc gap.4 bgf pd br tl">
            <li>
              <Link className="fx ac gap.4" to="/">
                <SvgIcon hash="home" width="24"/>
                Главная
              </Link>
            </li>
            <li>
              <Link className="fx ac gap.4" to="/create-task">
                <SvgIcon hash="addTodo" width="24"/>
                Создать задачу
              </Link>
            </li>
            <li>
              <Link className="fx ac gap.4" to="/profile">
                <SvgIcon hash="userProfile" width="24"/>
                Профиль
              </Link>
            </li>
            <li>
              <Link className="fx ac gap.4" to="/my-todos">
                <SvgIcon hash="myTodo" width="24"/>
                Мои задачи
              </Link>
            </li>
            {
              isAdmin && <li>
                <Link className="fx ac gap.4" to="/manage-users">
                  <SvgIcon hash="users" width="24"/>
                  Пользователи
                </Link>
              </li>
            }
            {
              isSuper && <li>
                <Link className="fx ac gap.4" to="/companies">
                  <SvgIcon hash="company" width="24"/>
                  Компании
                </Link>
              </li>
            }
            <li>
              <Link className={`pr fx ac gap.4 ${(notify) ? ' hNtfCl' : ''}`} to="/notify">
                <SvgIcon hash="notify" width="24"/>
                Оповещения
              </Link>
            </li>
            <li>
              <button
                className="fx ac gap.4 cb btn pd0"
                onClick={handleClickBtnExit}
              >
                <SvgIcon hash="exit" width="24"/>
                Выйти
              </button>
            </li>
          </ul>
        )}
      </span>
    </header>
  );
};

export default Header;