// src/features/profileSlice.js
import { createSlice } from '@reduxjs/toolkit';

const profileSlice = createSlice({
  name: 'profile',
  initialState: {
    data: {
      id: null,
      name: '',
      phone: '',
      email: '',
      avatar: '',
      rights: '',
      json: {
        g: '',
        ct: '',
        bd: '',
      },
    },
    isEditing: false,
  },
  reducers: {
    setProfileData: (state, action) => {
      const { id, name, phone, email, avatar, rights, json } = action.payload;

      state.data = {
        id: id || state.data.id,
        name: name || state.data.name,
        phone: phone || state.data.phone,
        email: email || state.data.email,
        avatar: avatar || state.data.avatar,
        rights: rights || state.data.rights,
        json: {
          ...state.data.json,
          ...(json ? JSON.parse(json) : {}),
        },
      };
    },
    toggleEditMode: (state) => {
      state.isEditing = !state.isEditing;
    },
    updatePhoto: (state, action) => {
      state.data.avatar = action.payload;
    },
    updateProfileField: (state, action) => {
      const { field, value } = action.payload;
      if (state.data[field] !== undefined) {
        state.data[field] = value;
      }
    },
    updateProfileFieldJSON: (state, action) => {
      const { field, value } = action.payload;
      const fields = field.split('.'); // Разбиваем путь на части (например, 'json.g')

      // Обрабатываем только поля с двумя уровнями вложенности, например, json.g
      if (fields.length === 2 && state.data[fields[0]]) {
        state.data[fields[0]][fields[1]] = value;
      }
    },
  },
});

export const { setProfileData, toggleEditMode, updatePhoto, updateProfileField , updateProfileFieldJSON} = profileSlice.actions;
export default profileSlice.reducer;