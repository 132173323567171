// src/pages/TaskView/TaskViewPage.js
import React, {useEffect, useState, useRef} from 'react';
import {Link, useNavigate} from "react-router-dom";
import useFetch from "../../utils/useFetch";
import GoToMP from "../../components/GoToMP";
import {formatDateDifference, formatDateToWordForm} from "../../utils/formatDateToD";
import {isObject} from "../../utils/checkIsObj";
// import getFileType from "../../utils/getFileType";
import Image from "../../components/Image";
import SvgIcon from "../../components/SvgIcon";
import Files from "../../components/Files";
import InputFile from "../../components/InputFile";
import settings from "../../utils/settings";
import './TaskView.css'
const TaskViewPage = () => {
  const navigate = useNavigate()
    , path = window.location.pathname // Получит "/task/284"
    , TID = Math.floor(path.split('/').pop()) // Разделяем по "/" и берём последний элемент
    , UID = localStorage.getItem('UID')
  ;
  if(!TID)navigate('/');
  const [todo, setTodo]=useState(null)
    , [clickedIndex, setClickedIndex] = useState(null)
    , [btnSendDis, setBtnSendDis] = useState(true)
    , [canEdit, setCanEdit] = useState(false)
    , [text, setText] = useState('')
    , [fileToDel,setFileToDel]=useState('')
    , [files,setFiles]=useState([])
    , [comments,setComments]=useState([])
    , [buttons, setButtons] = useState( ["Взять в работу", "Завершить", "Удалить"])
    , stateForCannotEdit =  ["Новая", "В работе", "Завершена"]
    , { setFetch: getTodoById, dataResponse: getTodoByIdData } = useFetch('todo/getTodoById','2')
    , { setFetch: setStateTodoById, dataResponse: setStateTodoByIdData }= useFetch('todo/setStateTodoById', null ) // Delete to_do
    , { setFetch: getCommentsByUidOrTid, dataResponse: getCommentsByUidOrTidData }= useFetch('comments/getCommentsByUidOrTid', null ) // Delete to_do
    , { setFetch: setDelFileFromSess/*, dataResponse: setDelFileFromSessData*/ }   = useFetch('file/setDelFileFromSess',fileToDel)
    , { setFetch: setComment, dataResponse: setCommentData }   = useFetch('comments/setComment',null)
    , { setFetch: getCheckCanEdit, dataResponse: getCheckCanEditData }   = useFetch('todo/getCheckCanEdit',null)
    , commentsGoToRef = useRef(null)
    , handleClick = (index) => {
        if(todo.state===2 && index===0) {// state=2 (в работе, клацаем на «в работе»)
          return null;
        }
        if(todo.state===1 && index!==0) {// state=1 (в раб.), клацаем не «в работе»
          alert('Задача должна быть сначала «взята в работу»');
          return null;
        }
        if(todo.state===3 && index===0) {// state=3 (заверш.), клацаем на «в работе»
          return null;
        }
        if(todo.state===3 && index===1) {// state=3 (заверш.), клацаем на «заверш»
          return null;
        }
        if(todo.state===1 && index===2) {// state=1 (в раб.), клацаем на «удалить»
          alert('Задача должна быть сначала «взята в работу», «завершена»');
          return null;
        }
        let state=index+2;
        if(state===4)state=0;// нажали на «удалить»
        setStateTodoById([todo.id,state]);
        setClickedIndex(index); // Устанавливаем индекс нажатой кнопки.
        // Обновляем текст кнопки в массиве
        setButtons((prevButtons) =>
          prevButtons.map((text, i) => {
            if (i === index) {
              return index === 0 ? "В работе" : index === 1 ? "Задача завершена" : text;
            }
            return text;
          })
        );
      }
    , handleInput=(e)=>{
        const val=e.target.value;
        setText(val)
        if(val.length>0){
          setBtnSendDis(false)
        }else{
          setBtnSendDis(true)
        }
      }
    , handleFileChange = async (event) => {
        let filesInput = event.target.files;
        if (filesInput.length > 10) {
          alert('Вы можете выбрать не более 10 файлов.');
          // Если больше 10 файлов, ограничиваем до 10.
          // Преобразуем FileList в массив, чтобы обрезать его длину
          filesInput = Array.from(filesInput).slice(0, 10);
        }
        try {
          const filesForm = new FormData();
          // Добавляем все выбранные файлы в formData
          if(filesInput.length){
            for (let i = 0; i < filesInput.length; i++) {
              filesForm.append('files[]', filesInput[i]);
            }
          }
          filesForm.append('set', 'file/setTodoUploadAllTypesMultiple');
          filesForm.append('location_', window.location.href);
          const tkn=localStorage.getItem('hash');
          if(!tkn)throw new Error('FER_RY7. No token');
          fetch(`${settings.REACT_APP_API_URL}/index.php?fetch=true`, {
            method: 'POST',
            body: filesForm,
            credentials: 'include',
            headers: {  // блок для заголовков
              'Accept': 'application/json, text/plain',
              'X-Token': tkn,
            },
          }).then(r=>r.json()).then(e=>{
            if(e?.s===1){
              const j = JSON.parse(e?.d);
              if (Array.isArray(j)) {
                setFiles(prevFiles => [
                  ...prevFiles,
                  ...j
                ]);
              }
            }
            if(e?.s===0 && e?.d)alert(e?.d)
          })
        } catch (e) {
          console.error('Ошибка:', e);
        }
      }
    , handleBtnSend=()=>{
        // Сообщение можно отправить без текста, если есть файлы
        // if(TID&&text&&text.length>1){
          setComment({
            TID:TID,
            text,
            files,
          })
        // }else{
        //   alert('01. Проверьте данные.')
        // }
      }
    , gotoComm=()=>commentsGoToRef.current?.scrollIntoView({ behavior: "smooth", block: "center" });

  useEffect(() => {
    getTodoById(TID);
    getCheckCanEdit(TID)
  }, []);
  useEffect(() => {
    if(getTodoByIdData&&getTodoByIdData.s===0){
      alert(getTodoByIdData.d)
      navigate('/')
    }
    if(getTodoByIdData&&getTodoByIdData.s===1) {
      // console.log(JSON.parse(getTodoByIdData.d))
      /**
       * {
       *     "id": 285,
       *     "UID": 4439,
       *     "text": "Открытие нового магазина в Москве",
       *     "resp": 4439,
       *     "state": 3,
       *     "title": "Открытие магазина на Арбате, 73е",
       *     "deadline": "2024-12-22",
       *     "updated": "2024-11-22 14:01:59",
       *     "datetime": "2024-11-23 10:30:00",
       *     "json": "{\"files\":[[\"123.jpg\",\"902.92 KB\"]],\"team\":[4439,4438,4437,4436],\"dep\":1,\"tmp_tid\":\"B7J9hFg7N123\",\"compledUser\":2}",
       *     "comments": 2,
       *     "votes": 1,
       *     "depName": "Маркетинг",
       *     "creatorName": "John Doe",
       *     "respName": "John Doe",
       *     "teamNames": "John Doe,Name,Name 2,Name1,+7-999-888-77-66",
       * }
       */
      const j=JSON.parse(getTodoByIdData.d)
      j.json=JSON.parse(j.json)
      setTodo(j)
      if(parseInt(j.UID)!==parseInt(UID)){
        if(buttons.length===3){
          setButtons( (prevButtons) => prevButtons.slice(0, -1));
        }
      }
      if(j.state!==undefined){
        if(j.state===0) {
          navigate('/')
        }
        setClickedIndex(j.state-2)
      }
    }
  }, [getTodoByIdData]);
  useEffect(() => {
    setButtons(buttons)
  }, [buttons]);
  useEffect(() => {
    setClickedIndex(clickedIndex)
    setButtons((prevButtons) =>
      prevButtons.map((text, i) => {
        if (i === clickedIndex) {
          return clickedIndex === 0 ? "В работе" : clickedIndex === 1 ? "Задача завершена" : text;
        }
        return text;
      })
    );
  }, [clickedIndex]);
  useEffect(() => {
    if(setStateTodoByIdData){
      if(setStateTodoByIdData.s===1){
        setTodo((prevTodo) => ({
          ...prevTodo,
          state: clickedIndex+2, // Изменяем статус
        }));
        if(clickedIndex===2)navigate('/',{replace:true})
      }
      if(setStateTodoByIdData.s===0){
        alert(setStateTodoByIdData.d)
      }
    }
  }, [setStateTodoByIdData]);
  // Comments
  useEffect(() => {
    getCommentsByUidOrTid({
      UIDorTID: TID,
      order:    1,
      limit:    50,
      offset:   0,
      type:     1,
    })
  }, []);
  useEffect(() => {
    if(getCommentsByUidOrTidData&&getCommentsByUidOrTidData?.s===1){
      const j = JSON.parse(getCommentsByUidOrTidData?.d)
      j.forEach(e=>e.json=JSON.parse(e?.json))
      setComments(j)
    }
  }, [getCommentsByUidOrTidData]);
  useEffect(() => {
    if(setCommentData&&setCommentData?.s===1){
      // После успешного ответа сбрасываем файлы, текст, кнопку
      setText('');
      setBtnSendDis(false)
      setFiles([])
      const newComments = JSON.parse(setCommentData?.d);
      newComments.forEach(ec=>ec.json=JSON.parse(ec?.json))
      if (Array.isArray(newComments)) {
        setComments(prevComments => [...newComments, ...prevComments]);
        // Вызывает ошибку, поэтому native...
        // commentsRef.current?.scrollIntoView({ behavior: "smooth", block: "center" });
        const z=document.querySelector('[name="comments"]');
        if(z)z.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }
    if(setCommentData&&setCommentData?.s===0){
      alert(setCommentData?.d)
    }
  }, [setCommentData]);
  // \ Comments
  useEffect(() => {
    if(getCheckCanEditData && getCheckCanEditData.s===1){
      const r=Math.floor(getCheckCanEditData.d)
      if(r===1){
        setCanEdit(true)
      }
    }
  }, [getCheckCanEditData]);
  useEffect(() => {
    if(files && files.length>0){
      setBtnSendDis(false)
    }
    if(files && files.length===0 && text && text.length===0){ // если файлов нет и текста нет, запретим отправку
      setBtnSendDis(true)
    }
  }, [files]);

  return (
    <div className="cnt mobPDx">
      <div className="cnt TP">
        {
          (todo) ? (
            <>
              <h1 className="fx ac fsb">
                {
                  canEdit ? <Link title="Редактировать задачу" className="fx ac gap" to={`/task-edit/${TID}`}><SvgIcon
                      hash="edit" width="28"/> {todo.title}</Link>
                    : todo.title
                }
                <GoToMP/>
              </h1>
              <hr/>
              <div className="pd br gap1 mobFx mobFdc mobGap TDCnt">
                <span className="fx gap.4 fdc TDCntSp">
                  <span className="fx gap1TDCntSp">
                    <span><strong>ID</strong> {todo.id}</span>
                    <span><strong>Отдел:</strong> {todo.depName}</span>
                  </span>
                  <span><strong>Голосов:</strong> {todo.votes}</span>
                  <span className="fx fdc gap TDCntSp">
                    <span><strong>Создана:</strong> {formatDateToWordForm(todo.datetime)}</span>
                    <span><strong>Дедлайн:</strong> {formatDateToWordForm(todo.deadline)}</span>
                    {
                      todo.json.inWorkDate &&
                      <span title={todo.json.inWorkDate}><strong>В работе:</strong> {formatDateDifference(todo.json.inWorkDate, true)}</span>
                    }

                  </span>
                </span>
                <span className="fx gap.4 fdc">
                  <span className="cb"><strong>Ответственный:</strong> {(todo.respName && true) ? todo.respName : '—'}</span>
                  <span><strong>Команда:</strong> {todo?.teamNames?.replace(/,/g, ", ") ?? '—'}</span>
                  <span><strong>Создал задачу:</strong> {(todo.creatorName) ? todo.creatorName : 'имя не заполнено, ID:' + todo.UID }</span>
                  {
                    !canEdit && <span><strong>Статус:</strong> {
                      stateForCannotEdit[todo.state-1]
                    }</span>
                  }
                </span>
                {(() => {
                  if (todo.json) {
                    if (isObject(todo.json) && todo.json?.files && todo.json?.files.length > 0) {
                      return (
                        <span className="fx fdc gap.4">
                          {(todo.json.files && todo.json.files.length > 0) &&
                            (
                              <Files files={todo.json.files} needBtnDel={false}/>
                            )
                          }
                        </span>
                      );
                    } else {
                      return <h4 className="tc w1">Файлы отсутствуют</h4>;
                    }
                  }
                })()}
              </div>
              {
                todo.text && <span className="my mb">
                    <h3 className="w1 db my">Описание задачи:</h3>
                  <pre>{todo.text}</pre>
                  </span>
              }
              {
                canEdit && <span>
                    <span className="fx fsb my TDBtnGrp">
                    <span> </span>
                    <span> </span>
                      {buttons.map((label, index) => (
                        <button
                          key={index}
                          className={`pr fwb ${
                            index === clickedIndex
                              ? "TDBtnGrpClOk" // Текущая нажатая кнопка
                              : clickedIndex !== null && index < clickedIndex
                                ? "TDBtnGrpCl" // Предыдущие кнопки
                                : ""
                          } ${index === buttons.length - 1 ? "TDBtnGrpLC" : ""}`} // Добавляем TDBtnGrpLC для последней кнопки
                          onClick={() => handleClick(index)}
                        >
                          {label}
                        </button>
                      ))}
                      <span> </span>
                    <span> </span>
                  </span>
                </span>
              }
              <hr/>
            </>
          ) : <h1 className="fx fsb w1 gap">Задача не найдена <GoToMP/></h1>
        }
      </div>
      <section>
        {
          (comments && comments.length > 0) ?
            <>
              <h3 className="fx fsb mt2">Обсуждения: <a href="#" onClick={gotoComm} className="fx ac gap"><SvgIcon
                hash="message"/><small>добавить
                комментарий</small></a></h3>
              <a name="comments">&nbsp;</a>
              <div className="TPC">
                {
                  comments.map(el => {
                    /**
                     * [
                     *     {
                     *         "id": 2,
                     *         "UID": 4439,
                     *         "TID": 285,
                     *         "dt": "2024-10-22 14:26:06",
                     *         "text": "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Facilis itaque laudantium repellat repudiandae. Atque, possimus.",
                     *         "json": {
                     *             "files": [
                     *                 [
                     *                     "20241022_091007_561_6717749b89000.zip",
                     *                     "86.33 KB"
                     *                 ],
                     *                 [
                     *                     "20241022_091007_562_6717749b8888.png",
                     *                     "492.81 KB"
                     *                 ]
                     *             ],
                     *             "state": 1
                     *         },
                     *         "userName": "John Doe",
                     *         "userAvatar": "7c5845cea683556d813ebd657e8cca011731947641"
                     *     },
                     *     {
                     *         "id": 1,
                     *         "UID": 4439,
                     *         "TID": 285,
                     *         "dt": "2024-10-22 14:25:06",
                     *         "text": "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Animi dolores et excepturi facere impedit itaque iusto laudantium libero nam placeat possimus sint tenetur, voluptatem voluptates!",
                     *         "json": {
                     *             "files": [
                     *                 [
                     *                     "20241022_091007_561_6717749b8901c.jpg",
                     *                     "86.33 KB"
                     *                 ],
                     *                 [
                     *                     "20241022_091007_562_6717749b89511.png",
                     *                     "492.81 KB"
                     *                 ],
                     *                 [
                     *                     "20241118_011137_741_673b417db4e3e.jpg",
                     *                     "49 KB"
                     *                 ],
                     *                 [
                     *                     "20241118_011137_741_673b417db4e3e.jpg",
                     *                     "49 KB"
                     *                 ],
                     *                 [
                     *                     "20241118_011137_741_673b417db4e3e.jpg",
                     *                     "49 KB"
                     *                 ],
                     *                 [
                     *                     "20241118_011137_741_673b417db4e3e.jpg",
                     *                     "49 KB"
                     *                 ],
                     *                 [
                     *                     "20241118_011137_741_673b417db4e3e.jpg",
                     *                     "49 KB"
                     *                 ],
                     *                 [
                     *                     "20241118_011137_741_673b417db4e3e.jpg",
                     *                     "49 KB"
                     *                 ],
                     *                 [
                     *                     "20241118_011137_741_673b417db4e3e.jpg",
                     *                     "49 KB"
                     *                 ],
                     *                 [
                     *                     "20241118_011137_741_673b417db4e3e.jpg",
                     *                     "49 KB"
                     *                 ]
                     *             ],
                     *             "state": 1
                     *         },
                     *         "userName": "John Doe",
                     *         "userAvatar": "7c5845cea683556d813ebd657e8cca011731947641"
                     *     }
                     * ]
                     * */
                    return <div className="fx ac gap my.5" key={el.id}>
                      <Image
                        URL={`${settings.REACT_APP_API_URL_IMG}${el.UID}/${el.userAvatar}_t`}
                      />
                      <span className="pr fx fdc gap.4 w1 pd TPCC">
                        <span className="fx ac gap1">
                          <span className="fwb TPCN">{el.userName}</span>
                          <small className="cg" title={el.dt}>{formatDateToWordForm(el.dt, true)}</small>
                        </span>
                        <span>{el.text}</span>
                        {
                          (el?.json?.files && el?.json?.files.length>0)?
                            (
                              <Files files={el?.json?.files} class_="TPFls mt.5" text={false}/>
                            ):(<></>)
                        }
                      </span>
                    </div>
                  })
                }
              </div>
            </>
            : <h2 className="w1 my tc">Комментарии отсутствуют</h2>
        }

        <h4 className="mt5 mb">Добавить комментарий:</h4>
        <textarea
          className="w1 p4 TPTa"
          rows="7" cols="7"
          placeholder="Текст комментария"
          onInput={handleInput}
          value={text}
        ></textarea>
        <div className="fx ac fsb gap1 mt my2 mobFdc mobFdcr">
          <button
            className="pd cf fwb w1/3 fs bgsky btnTPSndCmm"
            disabled={btnSendDis}
            onClick={handleBtnSend}
          >Отправить
          </button>
          <a name="add-comment" ref={commentsGoToRef}>&nbsp;</a>
          { (files && files.length > 0) && <Files files={files} class_="w1/2" needBtnDel={true} setFiles={setFiles} setDelFileFromSess={setDelFileFromSess}/> }
          <label className="cp fx fsb ac gap2 addFile">
            <span className="fx ac jc crttBtnAddFile">
              <SvgIcon hash="file" width="20"/>
            </span>
            <InputFile handleFileChange={handleFileChange}/>
            <span className="c6">Выбрать файл</span>
          </label>
        </div>
      </section>
    </div>
  );
};

export default TaskViewPage;