// src/components/PrivateRoute.js
import React, {Suspense, useEffect, useState} from 'react';
import {Navigate, useNavigate} from 'react-router-dom';
import useFetch from '../utils/useFetch';
import useIsMobile from "../utils/checkMobile";
import HeaderMobile from "./HeaderMobile";
import Header from "./Header";

const PrivateRoute = ({ children }) => {
  const token = localStorage.getItem('hash')
    // , [setF,setSetF]=useState(false)
    , { setFetch: getCheckAuth, dataResponse: getDataCheckAuth } = useFetch('auth/getCheckAuth','1')
    , navigate = useNavigate()
  ;
  useEffect(() => {
    // if(!setF){
      // setSetF(true)
      getCheckAuth(); // Выполняем запрос
    // }
  }, []);
  useEffect(()=>{
    if(getDataCheckAuth){
      if(getDataCheckAuth?.s===0){
        navigate('/auth', { replace: true });
      }
      if (getDataCheckAuth?.s===1) {
        const UID=parseInt(getDataCheckAuth?.d);
        if(UID&&UID>0){
          localStorage.setItem('UID',UID)
        }
      }
    }
  },[getDataCheckAuth])
  const isMobile = useIsMobile();

  if (!token) {
    // Если токен отсутствует, перенаправляем на страницу авторизации
    return <Navigate to="/auth" />;
  }
  return<Suspense fallback={<span>Загрузка...</span>}>
    {(isMobile) ? (
      <>
        <HeaderMobile />
        {children}
      </>
    ) : (
      <>
        <Header />
        {children}
      </>
    )}
  </Suspense>
   ; // Если токен существует, отображаем дочерние компоненты
};

export default PrivateRoute;