import { createSlice } from '@reduxjs/toolkit';

// Изначальное состояние
const initialState = {
  usersList: []
};

const usersMoreSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setUsers: (state, action) => {
      state.usersList = action.payload;
    },
    addUser: (state, action) => {
      state.usersList.push(action.payload);
    },
    removeUser: (state, action) => {
      state.usersList = state.usersList.filter(
        user => user.id !== action.payload
      );
    },
    updateUser: (state, action) => {
      const index = state.usersList.findIndex(user => user.id === action.payload.id);
      if (index !== -1) {
        state.usersList[index] = { ...state.usersList[index], ...action.payload };
      }
    }
  }
});

// Экспортируем действия для использования в компоненте
export const { setUsers, addUser, removeUser, updateUser } = usersMoreSlice.actions;

// Экспортируем редуктор для подключения к store
export default usersMoreSlice.reducer;