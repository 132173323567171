import changeStyle from "./changeStyle";

changeStyle('',true)
const loader = ()=>{
    fetch('/style/c7.css').then(e=>e.text()).then(e=>{
        const s=document.createElement('style');
        s.textContent=e;
        document.body.appendChild(s)
    })
}

export default loader;