// /src/pages/Company/CompanyPage.js
// Страница управления компаниями

import React, {useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import SvgIcon from "../../components/SvgIcon";
import GoToMP from "../../components/GoToMP";
import useFetch from "../../utils/useFetch";

import './Company.css'

const CompanyPage = () => {
  const nav= useNavigate()
    , [companies,setCompanies] = useState([])
    , [ppTitle,setPpTitle] = useState('Редактирование компании')
    , [ppType,setPpType] = useState(0) // 0 — add company, 1 — edit
    , [defCompany, setDefCompany] = useState({
        id:0,
        dt: new Date().toISOString().replace("T", " ").split(".")[0],
        name:'',
        state:'1'
      })
    , [pp, setPp] = useState(false) // popup
    , [ppEditData, setPpEditData] = useState([]) // popup edit data
    , [sortOrder, setSortOrder] = useState({
        column: null,
        direction: 'asc', // 'asc' - по возрастанию, 'desc' - по убыванию
      })
    , {setFetch: getCheckRights, dataResponse: getCheckRightsData} = useFetch('user/getCheckRights', '1')
    , {setFetch: getCompany, dataResponse: getCompanyData} = useFetch('company/getCompany', '1')
    , {setFetch: setSaveCompany, dataResponse: setSaveCompanyData} = useFetch('company/setSaveCompany', null)
    , [isSuper, setIsSuper] = useState(false)
    , sortBy = (columnIndex) => {
        let newSortOrder = { ...sortOrder };
        // Если та же колонка, меняем направление сортировки
        if (newSortOrder.column === columnIndex) {
          newSortOrder.direction = newSortOrder.direction === 'asc' ? 'desc' : 'asc';
        } else {
          newSortOrder.column = columnIndex;
          newSortOrder.direction = 'asc'; // по умолчанию сортируем по возрастанию
        }
        setSortOrder(newSortOrder);
        const sortedData = [...companies].sort((a, b) => {
          let aValue, bValue;
          // Определяем, какие поля сортировать в зависимости от индекса
          switch (columnIndex) {
            case 0:
              aValue = a.id;
              bValue = b.id;
              break;
            case 1:
              aValue = a.name;
              bValue = b.name;
              break;
            /*case 2:
              aValue = new Date(a.dt).getTime(); // Дата в миллисекундах для правильной сортировки
              bValue = new Date(b.dt).getTime();
              break;*/
            case 2:
              aValue = a.UID;
              bValue = b.UID;
              break;
            case 3:
              aValue = parseInt(a.state, 10); // Преобразуем state в число для сортировки
              bValue = parseInt(b.state, 10);
              break;
            default:
              return 0;
          }
          // Для сортировки по возрастанию или убыванию
          if (aValue < bValue) {
            return newSortOrder.direction === 'asc' ? -1 : 1;
          }
          if (aValue > bValue) {
            return newSortOrder.direction === 'asc' ? 1 : -1;
          }
          return 0;
        });
        setCompanies(sortedData);
      }
    , edit = (e)=>{
        setPpTitle('Редактирование компании')
        setPp(true);
        setPpEditData(e) // obj — single company
        setPpType(1)
      }
    , companyAdd = ()=>{
        setPpTitle('Добавить компанию')
        setPp(true);
        setPpEditData(defCompany) // obj — single company
        setPpType(0)
      }
    , handleChange = (e) => {
        let {name,value}=e.target;
        setPpEditData({
          ...ppEditData,
          [name]: value, // Обновляем поле по имени
        });
      }
    , closePp=(e)=>{
        if(
          e.target.classList.contains('popup')
          || e.target.classList.contains('popupClose')
        ){
          setPp(false);
          setPpEditData([])
        }
      }
    , handleSave=()=>{
        setSaveCompany(ppEditData)
        if(ppType===1){ // Если ред., то обновляем старые данные
          setCompanies((prevCompanies) =>
            prevCompanies.map((company) =>
              company.id === ppEditData.id ? ppEditData : company
            )
          );
        }else{// если создаём, то запрашиваем с сервера
          getCompany();
          setPp(false);
        }
      }
  ;
  useEffect(() => {
    getCheckRights()
    getCompany()
    // getForUM()
  }, []);
  useEffect(() => {
    if(getCheckRightsData && getCheckRightsData.s===1){
      const r=parseInt(getCheckRightsData.d)
      if(r !== 2) {
        alert('У Вас нет прав доступа к данной странице.')
        nav('/')
      }
      setIsSuper(r === 2)
    }
  }, [getCheckRightsData]);
  useEffect(() => {
    if(getCompanyData && getCompanyData.s===1 && getCompanyData.d){
      try {
        const j=JSON.parse(getCompanyData.d);
        setCompanies(j)
      }catch(e){
        alert(e)
      }
    }
    if(getCompanyData && getCompanyData.s===0 && getCompanyData.d){
      alert(getCompanyData.d)
    }
  }, [getCompanyData]);
  useEffect(() => {
    if(setSaveCompanyData && setSaveCompanyData.d){
      alert(setSaveCompanyData.d)
    }
  }, [setSaveCompanyData]);

  return isSuper ? <div className="cnt mobPDx USRS CMPN">
    <h1 className="fx ac fsb gap">
      <span className="fx ac gap">
        <SvgIcon hash="company" width="36"/>
        Компании
      </span>
      <GoToMP/>
    </h1>
    <button className="fx ac gap fwb btn my2"
            onClick={companyAdd}
    >
      <SvgIcon hash="company-add"/> Добавить компанию
    </button>
    {
      (companies && companies.length>0)?
        <ul className="my2 table">
          <li>
            <span className="fwb cp ns mobDn" onClick={()=>{sortBy(0)}}>ID</span>
            <span className="fwb cp ns mobDn" onClick={()=>{sortBy(1)}}>Название</span>
            <span className="fwb ns mobDn">Описание</span>
            <span className="fwb cp ns mobDn" onClick={()=>{sortBy(2)}}>Кто создал</span>
            <span className="fwb cp ns mobDn" onClick={()=>{sortBy(3)}}>Состояние</span>
            <span>Ред.</span>
          </li>
          {
            /**
             * [{"id":1,"dt":"2024-12-11 14:26:05","name":"Name of company","state":"0","UNM":"John Doe","UPH":"+7-000-111-22-33"}]
             */
            companies.map(e => {
              return <li key={e.id}>
                <span>{e.id}</span>
                <span>{(e.name !== '') ? e.name : '—'}</span>
                <span>{e.text}</span>
                <span>
                  <q className="fx ac gap">
                    <small>ID: {e.UID}</small> {(e.UNM !== '') ? e.UNM : e.UPH}
                  </q>
                </span>
                <span>{
                  (()=>{
                    switch (e.state) {
                      case '0':
                        return 'Удалена';
                      case '1':
                        return 'Активная';
                      case '2':
                        return 'Заблокирована';
                    }
                  })()
                }</span>
                <span>
                    <button className="btn"
                            onClick={()=>{edit(e)}}
                    >
                      <SvgIcon hash="edit"/>
                    </button>
                  </span>
              </li>
            })
          }
        </ul>
        : <h1 className="w1 tc my2">Компании не обнаружены</h1>
    }
    {
      (pp && ppEditData) && <div className="pf in0 z11 popup" onClick={closePp}>
        <div className="popupC bgf pd br">
          <h2 className="fx fsb gap mb">
                  <span className="fx ac gap.5">
                    <SvgIcon hash="edit"/>
                    {ppTitle} {
                                (()=> {
                                  if(ppEditData.id)return <small>ID: {ppEditData.id}</small>
                                })()
                              }
                  </span>
            <button className="fs0 btn popupClose"
                    onClick={closePp}
            >Close
            </button>
          </h2>
          <span className="fx fdc gap mb">
            <q>Дата создания: <small>{ppEditData.dt}</small></q>
            {
              (()=> {
                if (ppEditData.UNM || ppEditData.UPH) return <q>Создал: {(ppEditData.UNM !== '') ? ppEditData.UNM : ppEditData.UPH}</q>
              })()
            }
          </span>
          <span className="fx fdc gap1 mb">
            <label className="fwb">Название:</label>
            <input
              onChange={handleChange}
              name="name"
              value={ppEditData.name}/>
          </span>
          <h5>Описание:</h5>
          <textarea className="mt.5 mw1" name="text" maxLength="2000" onChange={handleChange}>{ppEditData.text}</textarea>
          <small>Макс.: 2000 символов.</small>
          <h5 className="mt">Статус:</h5>
          <span className="fx fsb gap1 my">{/*0 — удалён, 1 — активен, 2 — заблокирован*/}
            <label className="radio-label">
                <input
                  type="radio"
                  value="1"
                  name="state"
                  checked={ppEditData.state === '1'}
                  onChange={handleChange}
                />
                <span className="custom-radio"></span>
                активна
              </label>
              <label className="radio-label">
                <input
                  type="radio"
                  value="0"
                  name="state"
                  checked={ppEditData.state === '0'}
                  onChange={handleChange}
                />
                <span className="custom-radio"></span>
                удалена
              </label>
              <label className="radio-label">
                <input
                  type="radio"
                  value="2"
                  name="state"
                  checked={ppEditData.state === '2'}
                  onChange={handleChange}
                />
                <span className="custom-radio"></span>
                заблокирована
              </label>
            </span>
          <button className="pd bgsky cf fwb tu" onClick={handleSave}>Сохранить</button>
        </div>
      </div>
    }
  </div> : <h1 className="w1 my2 tc">Нет прав доступа</h1>
}

export default CompanyPage