const getFileType = (fileName)=> {
  // Определение типа файла по расширению
  const extImg = ['jpg', 'jpeg', 'png', 'gif', 'webp', 'avif'],
    extArch = ['7z', 'zip', 'rar', 'tar'],
    extDoc = ['pdf', 'doc', 'docx', 'odt', 'ppt', 'pptx', 'xls', 'xlsx', 'txt', 'rtf', 'ods', 'odp'];

// Проверяем, что fileName является строкой и содержит точку
  let fileExtension;
  if (typeof fileName === 'string' && fileName.includes('.')) {
    fileExtension = fileName.split('.').pop().toLowerCase();
  } else {
    console.error('Invalid fileName:', fileName);
    fileExtension = null; // или значение по умолчанию
  }

// Дальнейшая логика, например, проверка типа файла
  if (fileExtension) {
    if (extImg.includes(fileExtension)) {
      return 'image';
    } else if (extArch.includes(fileExtension)) {
      return 'archive';
    } else if (extDoc.includes(fileExtension)) {
      return 'doc';
    } else {
      return 'other'; // для других типов файлов
    }
  }
}

export default getFileType