import React, {useEffect, useState} from 'react'
import useFetch from "../../utils/useFetch";
import { Link } from 'react-router-dom';
import './Notify.css'
import SvgIcon from "../../components/SvgIcon";
import GoToMP from "../../components/GoToMP";

const NotifyPage=()=>{

  const [notifyes,setNotifyes]=useState([])
      , [hasUnread,setHasUnread]=useState(false)
      , { setFetch: getNotify, dataResponse: getNotifyData } = useFetch('notify/getNotify','1')
      , { setFetch: setNotifyRead } = useFetch('notify/setNotifyRead','1')
      , notifyRemove=(id, event)=>{
          const clcElem=(event.target)
              , parentDiv= clcElem.closest('.NPUnrd')
              , parentBtn= clcElem.closest('button')
          ;
          if(parentDiv){
            parentDiv.classList.remove('NPUnrd')
          }
          if(parentBtn){
            parentBtn.classList.add('dn')
          }
          setNotifyRead({
            NID:id,
            state:1
          })
        }
      , handleReadAll=(e)=>{
          const divs= document.querySelectorAll('.NPUnrd')
              , btns= document.querySelectorAll('.NPUnrd button')
              , hNtfCl= document.querySelectorAll('.hNtfCl')
              , thisBtn=e.target
          ;
          if((divs&&divs.length>0)){
            divs.forEach(e=>e.classList.remove('NPUnrd'))
          }
          if(btns&&btns.length>0){
            btns.forEach(e=> e.classList.add('dn'))
          }
          if(thisBtn){
            thisBtn.classList.add('dn')
          }
          if(hNtfCl&&hNtfCl.length>0){
            hNtfCl.forEach(e=>e.classList.remove('hNtfCl'))
          }
          setNotifyRead({
            NID:'all',
            state:1
          })
        }
      /*, handleUpdate=()=>{
          const UID = localStorage.getItem('UID')
          if(!UID){
            alert('FER_NTF_6H. Возникла ошибка.')
          }else{
            getNotify({
              UID,
              limit:'50',
              order:'1',
              state:'2',
            })
          }
        }*/
  ;
  useEffect(() => {
    const UID = localStorage.getItem('UID')
    if(!UID){
      alert('FER_NTF_6H. Возникла ошибка.')
    }else{
      getNotify({
        UID,
        limit:'50',
        order:'1',
        state:'2',
      })
    }
  }, []);
  useEffect(() => {
    if(getNotifyData && getNotifyData.s===1){
      const j=JSON.parse(getNotifyData.d)
          , hasUnread = j.some(notify => notify.readed === "0")
      if(hasUnread)setHasUnread(true)
      setNotifyes(j)
    }
    if(getNotifyData && getNotifyData.s===0){
      if(getNotifyData?.d)alert(getNotifyData.d)
    }
  }, [getNotifyData]);
  return (
    <div className="cnt NP mobPDx">
      <h1 className="fx fsb ac gap my">
        <span className="fx ac gap">
          <SvgIcon hash="notify" width="36"/>
          Оповещения
        </span>
        <GoToMP/>
      </h1>
      <span className="fx gap1 my NPBtnReadAll">
        {/*<button className="fx ac gap pd bgb fwb cf" onClick={handleUpdate}><SvgIcon hash="update" class_="fbr"/> Обновить</button>*/}

        {
          hasUnread &&
          <button className="fx ac gap pd fwb"
                  onClick={handleReadAll}
          ><SvgIcon hash="readNotifyAll"/>Прочитать все</button>

        }
      </span>
      {
        (notifyes && notifyes.length > 0) ?
          <ul className="table mb">
            <li className="mobDn">
              <span className="mobDn tc"><strong>Дата</strong></span>
              <span className="mobDn tc"><strong>Сообщение</strong></span>
              <span className="mobDn tc"><strong>&nbsp;</strong></span>
            </li>
            {/*{"id":77,"dt":"2024-11-27 13:34:35","UID":4439,"msg":"Вас пригласили в задачу https:\\/\\/front.localhost.com\\/task\\/280","readed":"0"}*/}
            {
              notifyes.map(e => {
                const urlRegex = /(https?:\/\/[^\s]+)/g;
                const messageParts = e.msg.split(urlRegex);  // Разделяем строку на части

                // Преобразуем текст в массив React элементов
                const messageWithLinks = messageParts.map((part, index) => {
                  if (part.match(urlRegex)) {
                    return <Link key={index} to={part}>{part}</Link>;
                  }
                  return part; // Возвращаем обычный текст
                });

                return (
                  <li className={`${e.readed === '0' ? ' NPUnrd' : ''}`} key={e.id}>
                    <span>{e.dt}</span>
                    <span><pre>{messageWithLinks}</pre></span>
                    <span>{e.readed === '0' ? <button onClick={(event) => {
                      notifyRemove(e.id, event)
                    }} title="Прочитать"><SvgIcon hash="readNotify"/><q>Прочитать</q></button> : ''}</span>
                  </li>
                );
              })
            }
          </ul>
          : <h2 className="w1 my2 tc">Уведомления отсутствуют</h2>
      }
    </div>
  )
}
export default NotifyPage