// src/app/store.js
import { configureStore } from '@reduxjs/toolkit';
import { thunk } from 'redux-thunk';
import authReducer from '../features/authSlice';
import profileReducer from '../features/profileSlice';
import usersSingleReducer from "../features/usersSingleSlice";
import usersMoreReducer from "../features/usersMoreSlice";
import taskReducer from '../features/taskSlice';
import departmentReducer from '../features/departmentSlice';
import searchReducer from '../features/searchSlice';
import notificationSlice from "../features/notificationSlice";


export const store = configureStore({
  reducer: {
    auth: authReducer,
    profile: profileReducer,
    tasks: taskReducer,
    departments: departmentReducer,
    search: searchReducer,
    usersSingle: usersSingleReducer,
    usersMore: usersMoreReducer,
    notification: notificationSlice,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk),
});

export default store;