function show(el) {el.classList.remove('alertClosed')}
function close(el) {el.classList.add('alertClosed')}
const _AlertCustom=(text, prompt=false, cb=()=>{})=>{
  const d=document
      , al=d.querySelector('.alert')
  if(!al){
    const alr=d.createElement('div');
    alr.classList.add('pf','alert','fx','ac','jc','ovh')
    if(prompt) {
      alr.classList.add('alertPrompt');
    }else{
      show(alr)
    }
    alr.innerHTML=`
    <div class="fx fdc ac gap2 alertD">
      <h1 class="fx fsb gap1 w1">
        <span></span>
        Уведомление
        <button class="fs0 alertClose" title="Закрыть">x</button>
      </h1>
      <pre class="fwb fs18 ova alertText">${text}</pre>
      <span class="fsa w1 gap dn alertPromptBtn">
        <button class="br fwb fs18 pd alertPromptN">Нет</button>
        <button class="br fwb fs18 pd cf alertPromptY">Да</button>
      </span>
    </div>
    `;
    d.body.appendChild(alr);
    const alertClose=d.querySelectorAll('.alertClose,.alertPromptN')
        , alertPromptY=d.querySelector('.alertPromptY')
    if(alertClose && alertClose.length>0){
      alertClose.forEach(e=>e.addEventListener('click',()=> {
        close(alr)
        if(prompt)cb(false)
        return false;
      },false))
    }
    if(prompt && alertPromptY){
      alertPromptY.addEventListener('click',()=>{
        close(alr)
        cb(true)
        return true;
      },false)
    }
  }else{
    // есть в DOM
    const txt=d.querySelector('.alertText');
    if(txt)txt.textContent=text;
    if(prompt){
      al.classList.add('alertPrompt');
    }else{
      al.classList.remove('alertPrompt');
      show(al)
    }
    al.classList.remove('alertClosed')
  }
  const el=d.querySelector('.alert');
  if(el){
    el.addEventListener('click',(e)=>{
      if(el===e.target){
        close(el)
      }
    },false)
  }
}

export default _AlertCustom