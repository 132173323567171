
import React, {useEffect, useState} from 'react';
import useFetch from "../../utils/useFetch";
import {Link, useNavigate} from "react-router-dom";
import SvgIcon from "../../components/SvgIcon";
import DropDown from "../../components/DropDown";
import Files from "../../components/Files";
import InputFile from "../../components/InputFile";
import {useDispatch, useSelector} from "react-redux";
import formatPhone from "../../utils/formatPhone";
import settings from "../../utils/settings";
import {addDepartment, setDepartments} from "../../features/departmentSlice";
import {setUsers} from "../../features/usersSingleSlice";
import {setUsers as setUsersMore} from "../../features/usersMoreSlice";
import GoToMP from "../../components/GoToMP";

import './TaskEdit.css'
const TaskEditPage = () => {

  const path = window.location.pathname // Получит "/task/284"
      , TID = Math.floor(path.split('/').pop()) // Разделяем по "/" и берём последний элемент
      , navigate= useNavigate()
      , UID = localStorage.getItem('UID')
  ;
  if(!TID)navigate('/');
  const { setFetch: getTodoById, dataResponse: getTodoByIdData } = useFetch('todo/getTodoById',null)
    , [todo, setTodo]=useState(null)
    , dispatch = useDispatch()
    , departments = useSelector((state) => state.departments.departmentsList)
    , usersSingle = useSelector((state) => state.usersSingle.usersList)
    , usersMore   = useSelector((state) => state.usersMore.usersList)
    , [addDep,setAddDep]=useState(false)
    , [files,setFiles]=useState([])
    , [checkForm,setCheckForm]=useState([1,2])
    , [isTeamRequested,setIsTeamRequested]=useState(false)
    , [searchUserSingle,setSearchUserSingle]=useState('')
    , [searchUserMore,setSearchUserMore]=useState('')
    , [dep,setDep]=useState(0)
    , [activeDepartmentId, setActiveDepartmentId] = useState(0)
    , [state, setState] = useState(0)
    , [name, setName] = useState('')
    , [date, setDate] = useState('')
    , [text, setText] = useState('')
    , [resp, setResp] = useState([])
    , [team, setTeam] = useState([])
    , [clickedIndex, setClickedIndex] = useState(0)
    , [buttons, setButtons] = useState( ["Новая","Взять в работу", "Завершить", "Удалить"])
    , getDefaultDate = () => {
        const dt = new Date();
        dt.setDate(dt.getDate() + 7); // Добавляем 7 дней к текущей дате
        const formattedDate = dt.toISOString().split('T')[0]; // Форматируем в 'YYYY-MM-DD'
        if(date&&date==='')setDate(formattedDate); // Устанавливаем значение в состояние
        return formattedDate
      }
    , { setFetch: setNewDepartment, dataResponse: setNewDepartmentData } = useFetch('todo/setNewDepartment',dep)
    , { setFetch: getDepartments, dataResponse: getDepartmentsData }     = useFetch('todo/getDepartments','1')
    , { setFetch: get50Single, dataResponse: get50SingleData }           = useFetch('user/get50','1')
    , { setFetch: get50More, dataResponse: get50MoreData }               = useFetch('user/get50','1')
    , { setFetch: getSingleSearch, dataResponse: getSearchSingleData }   = useFetch('user/getSearch',searchUserSingle)
    , { setFetch: getMoreSearch, dataResponse: getSearchMoreData }       = useFetch('user/getSearch',searchUserMore)
    , { setFetch: setInviteBySmsS, dataResponse: setInviteBySmsSData }   = useFetch('user/setInviteBySms', {phone:formatPhone(searchUserSingle), t:0, tmpTodoID:window.tmpTodoID})
    , { setFetch: setInviteBySmsM, dataResponse: setInviteBySmsMData }   = useFetch('user/setInviteBySms',{phone:formatPhone(searchUserMore),t:1, tmpTodoID:window.tmpTodoID})
    , { setFetch: setDelFileFromTodo/*, dataResponse: setDelFileFromTodoOrSessData*/ }   = useFetch('file/setDelFileFromTodo',null)
    , { setFetch: setEdit, dataResponse: setEditData }   = useFetch('todo/setEdit',{
        id:TID,  state:state+1,  title: name,  deadline:date,  dep: activeDepartmentId,  text,  resp: resp[0] ?? 0,  team: team.join(',') ?? 0,  files
      })
    , { setFetch: setStateTodoById, dataResponse: setStateTodoByIdData }= useFetch('todo/setStateTodoById', null ) // Delete to_do
    , handleClcAddDep=()=>setAddDep(e=>!e)
    , handleChangeState = (index) => {
        if(index===3 ?? buttons.length===4){ // нажали на «Удалить»
          const cb=(e)=>{
            if(e===true){
              setStateTodoById([TID,0]);
            }
          }
          alert('Вы действительно хотите удалить задачу?',true,cb);
        }
        setState(index);
        setClickedIndex(index);
        setButtons((prevButtons) =>
          prevButtons.map((text, i) => {
            if (index === 0) {
              // Если нажали на "Новая", меняем обе кнопки
              if (i === 0) return "Новая";
              if (i === 1) return "Взять в работу";
              if (i === 2) return "Завершить";
            }
            if (index === 1) {
              // Если нажали на "Взять в работу", меняем обе кнопки
              if (i === 0) return "Новая";
              if (i === 1) return "В работе";
              if (i === 2) return "Завершить";
            }
            if (index === 2) {
              // Если нажали на "Взять в работу", меняем обе кнопки
              if (i === 0) return "Новая";
              if (i === 1) return "Взять в работу";
              if (i === 2) return "Задача завершена";
            }
            return text; // Остальные кнопки остаются неизменными
          })
        );
      }
    , handleSaveDep=()=> {
        if(dep&&dep.length<2){
          alert('01. Укажите название');
          return null;
        }
        if(dep&&dep.length>80){
          alert('02. Слишком длинное название. Сократите');
          return null;
        }
        setNewDepartment()
        setAddDep(false)
      }
    , handleSetDep=e=>{
        const v=e.target.value;
        if(v){
          if(v.length>80){
            alert('03. Слишком длинное название. Сократите.');
            return null;
          }
          setDep(v)
        }
      }
    , handleDepClick   = depId => {
        setActiveDepartmentId(depId);
        setCheckForm(prev=>{
          // Проверяем, есть ли 1 в массиве prev
          if (!prev.includes(1)) {
            return [...prev, 1];
          }
          return prev; // Если 1 уже есть, возвращаем массив без изменений
        })
      }
    , handleFileChange = async (event) => {
        let filesInput = event.target.files;

        if (filesInput.length > 10) {
          alert('04. Вы можете выбрать не более 10 файлов.');
          // Если больше 10 файлов, ограничиваем до 10.
          // Преобразуем FileList в массив, чтобы обрезать его длину
          filesInput = Array.from(filesInput).slice(0, 10);
        }
        try {
          const filesForm = new FormData();
          // Добавляем все выбранные файлы в formData
          if(filesInput.length){
            for (let i = 0; i < filesInput.length; i++) {
              filesForm.append('files[]', filesInput[i]);
            }
          }
          filesForm.append('set', 'file/setTodoUploadAllTypesMultiple');
          filesForm.append('location_', window.location.href);
          const tkn=localStorage.getItem('hash');
          if(!tkn)throw new Error('05. No token');
          fetch(`${settings.REACT_APP_API_URL}/index.php?fetch=true`, {
            method: 'POST',
            body: filesForm,
            credentials: 'include',
            headers: {  // блок для заголовков
              'Accept': 'application/json, text/plain',
              'X-Token': tkn,
            },
          }).then(r=>r.json()).then(e=>{
            if(e?.s===1){
              const j = JSON.parse(e?.d);
              if (Array.isArray(j)) {
                setFiles(prevFiles => [
                  ...prevFiles,
                  ...j
                ]);
              }
            }
            if(e?.s===0 && e?.d)alert(e?.d)
          })
        } catch (e) {
          console.error('Ошибка:', e);
        }
      }
    , handleSearchSingleUser= e =>{
        const v=e?.target?.value
        if(v.length>80){
          alert('06. Разрешено не более 80-ти символов');
          return null;
        }
        if(v&&v?.length&&v.length>1&&v.length<80){
          setSearchUserSingle(v)
        }
      }
    , handleSetSearchSingleUser=()=>{
        if(searchUserSingle.length<2){
          alert('07. Слишком мало символов для поиска');
          return null;
        }
        if(searchUserSingle.length>80){
          alert('08. Разрешено не более 80-ти символов');
          return null;
        }
        getSingleSearch()
      }
    , handleSearchMoreUser= e =>{
        const v=e?.target?.value
        if(v.length>80){
          alert('09. Разрешено не более 80-ти символов');
          return null;
        }
        if(v&&v?.length&&v.length>1&&v.length<81){
          setSearchUserMore(v)
        }
      }
    , handleSetSearchMoreUser=()=>{
        if(searchUserMore.length<2){
          alert('010. Слишком мало символов для поиска');
          return null;
        }
        if(searchUserMore.length>80){
          alert('011. Разрешено не более 80-ти символов');
          return null;
        }
        getMoreSearch()
      }
    , handleSetInviteSingleUser= () =>{
        const p=formatPhone(searchUserSingle);
        if(
          p
          &&p.length!==16
        ){
          alert('012. Проверьте номер телефона');
          return null;
        }
        setInviteBySmsS()
      }
    , handleSetInviteMoreUser= () =>{
        setInviteBySmsM()
      }
    // , handleRemoveFileFromTodo=(f) => {
    //     setFileToDel(f);
    //     setFiles(prevFiles => prevFiles.filter(file => file[0] !== f));
    //   }
    , handleNameChange = (event) => {
        const name=event.target.value;
        setName(name);
        if(name.length<1) {
          setCheckForm((prevState) => prevState.filter(item => item !== 2))
        }else{
          setCheckForm(prev=> {
            // Проверяем, есть ли 1 в массиве prev
            if (!prev.includes(2)) {
              return [...prev, 2];
            }
            return prev; // Если 2 уже есть, возвращаем массив без изменений
          })
        }
      }
    , handleDateChange = (event) => {
        setDate(event.target.value);
      }
    , handleTextChange = (event) => {
        setText(event.target.value);
      }
    , handleSaveTodo = (event) => {
        setEdit()
      }
  ;
  // Отделы
  useEffect(() => {
    // Вызываем setNewDepartment для запроса данных об отделах с сервера
    getDepartments();
    // get50Single();
    // get50More()
  }, []);
  useEffect(() => {
    // Когда ответ получен, обновляем Redux
    if (getDepartmentsData) {
      const dep=JSON.parse(getDepartmentsData.d)
      dispatch(setDepartments(dep));
    }
  }, [dispatch, getDepartmentsData]);
  // \ Отделы
  // Пользователи
  useEffect(() => {
    // Вызываем setNewDepartment для запроса данных о отделах с сервера
    if(resp && resp > 0) {
      let v=resp
      if(v===0) {
        get50Single('1');
      }else{
        get50Single(['1', v]);
      }
    }else{
      get50Single('1');
    }
    // get50More();
  }, [resp]);
  useEffect(() => {
    // Вызываем get50More для запроса пользователей с сервера
    if(team && team.length>0 && !isTeamRequested) {
      setIsTeamRequested(true)
      get50More(['1', team]);
    }
  }, [team]);
  useEffect(() => {
    get50More()
    setIsTeamRequested(true)
  }, []);
  useEffect(() => {
    // Универсальная обработка данных
    const handleResponse = (data, action) => {
      if (data) {
        const parsedData = JSON.parse(data.d);
        dispatch(action(parsedData));
      }
    };
    // Пользователи для одиночного выбора
    handleResponse(get50SingleData, setUsers);
    handleResponse(get50MoreData, setUsersMore);
  }, [get50SingleData,get50MoreData]);
  // \ Пользователи
  useEffect(() => {
    // Когда ответ получен, обновляем Redux
    if (setNewDepartmentData) {
      const id=setNewDepartmentData.d; // ID
      dispatch(addDepartment({id, title:dep}));
      setDep('')
    }
  }, [setNewDepartmentData]);
  useEffect(()=>{
    if(getSearchSingleData&&getSearchSingleData?.s===0) {
      alert(getSearchSingleData.d)
    }
    if(getSearchSingleData&&getSearchSingleData?.s===1&&getSearchSingleData?.d){
      const j=JSON.parse(getSearchSingleData.d)
      dispatch(setUsers(j));
    }
  },[getSearchSingleData])
  useEffect(()=>{
    if(getSearchMoreData&&getSearchMoreData?.s===0) {
      alert(getSearchMoreData.d)
    }
    if(getSearchMoreData&&getSearchMoreData?.s===1&&getSearchMoreData?.d){
      const j=JSON.parse(getSearchMoreData.d)
      dispatch(setUsersMore(j));
    }
  },[getSearchMoreData])

  useEffect(()=>{
    if(setInviteBySmsSData&&setInviteBySmsSData.d){
      alert(setInviteBySmsSData.d)
    }
  },[dispatch,setInviteBySmsSData])
  useEffect(()=>{
    if(setInviteBySmsMData&&setInviteBySmsMData.d){
      alert(setInviteBySmsMData.d)
    }
  },[dispatch,setInviteBySmsMData])
  useEffect(()=>{
    if(files.length>10){
      alert('013. Файлов не должно быть более 10-ти штук.');
      setFiles(prevFiles => prevFiles.slice(0, 10));
    }
  },[files])
  useEffect(() => {
    if (!date) {
      const defaultDate = getDefaultDate();
      setDate(defaultDate); // Устанавливаем дату по умолчанию
    }
  }, [date]);
  useEffect(() => {
    getTodoById([TID,1]);
  }, []);
  useEffect(() => {
    if(getTodoByIdData && getTodoByIdData.s===1){
      const r=getTodoByIdData.d
          , rd=Math.floor(r)
      if(rd===0){
        alert('014. Права на редактирование отсутствуют.')
        navigate('/',{replace:true})
      }
      const rj=JSON.parse(r); // основные данные
      if(rj.resp)setResp([rj.resp])
      if(rj.state) {
        setState(rj.state - 1)
        setClickedIndex(rj.state - 1)
      }
      if(rj.title)setName(rj.title)
      if(rj.text)setText(rj.text)
      if(rj.json){
        const jj=JSON.parse(rj.json) // json данные в основных
        if(jj)rj.json=jj;
        setTodo(rj);
        if(parseInt(rj.UID)!==parseInt(UID)){
          if(buttons&&buttons.length===4){
            setButtons((prevButtons) => prevButtons.slice(0, -1));
          }
        }
        /**
         * jj >
         * {
         *     "files": [
         *         [
         *             "123.jpg",
         *             "902.92 KB"
         *         ],
         *         [
         *             "124.jpg",
         *             "902.92 KB"
         *         ],
         *         [
         *             "125.jpg",
         *             "78 KB"
         *         ],
         *         [
         *             "126.jpg",
         *             "612 KB"
         *         ],
         *         [
         *             "127.jpg",
         *             "292 KB"
         *         ],
         *         [
         *             "128.jpg",
         *             "992 KB"
         *         ],
         *         [
         *             "129.jpg",
         *             "122 KB"
         *         ],
         *         [
         *             "130.jpg",
         *             "92.92 KB"
         *         ]
         *     ],
         *     "team": [
         *         5000,
         *         4439,
         *         4438,
         *         4437,
         *         4436,
         *         36
         *     ],
         *     "dep": 1,
         *     "tmp_tid": "B7J9hFg7N123",
         *     "compledUser": 4439,
         *     "inWorkDate": "2024-11-22 14:45:55"
         * }
         */

        if(jj.dep)setActiveDepartmentId(jj.dep)
        if(jj.files && jj.files.length>0)setFiles(jj.files)
        if(jj.team)setTeam(jj.team)
      }
    }
  }, [getTodoByIdData]);
  useEffect(() => {
    setButtons(buttons)
  }, [buttons]);
  useEffect(() => {
    setClickedIndex(clickedIndex)
    setButtons((prevButtons) =>
      prevButtons.map((text, i) => {
        if (i === clickedIndex) {
          if (i === 0) return "Новая";
          if (i === 1) return "В работе";
          if (i === 2) return "Задача завершена";
        }
        return text; // Возвращаем текст без изменений, если не нужно менять
      })
    );
  }, [clickedIndex]);
  useEffect(() => {
    if(setStateTodoByIdData && setStateTodoByIdData.s===0){
      if(setStateTodoByIdData.d)alert(setStateTodoByIdData.d)
    }
    if(setStateTodoByIdData && setStateTodoByIdData.s===1){
      navigate('/',{replace:true})
    }
  }, [setStateTodoByIdData]);
  useEffect(() => {
    if(setEditData && setEditData.s===1 && setEditData.d){
      alert(setEditData.d)
    }
    if(setEditData && setEditData.s===0 && setEditData.d){
      alert(setEditData.d)
    }
  }, [setEditData]);
  /**
   * to_do >
   * {
   *     "id": 285,
   *     "UID": 4439,
   *     "text": "Открытие нового магазина в Москве",
   *     "resp": 4439,
   *     "state": 2,
   *     "title": "Открытие магазина на Арбате, 73е",
   *     "deadline": "2024-12-22",
   *     "updated": "2024-11-28 16:24:53",
   *     "datetime": "2024-11-23 10:30:00",
   *     "json": "{\"files\": [[\"123.jpg\", \"902.92 KB\"],[\"124.jpg\", \"902.92 KB\"],[\"125.jpg\", \"78 KB\"],[\"126.jpg\", \"612 KB\"],[\"127.jpg\", \"292 KB\"],[\"128.jpg\", \"992 KB\"],[\"129.jpg\", \"122 KB\"],[\"130.jpg\", \"92.92 KB\"]], \"team\": [5000,4439, 4438, 4437, 4436, 36], \"dep\": 1, \"tmp_tid\": \"B7J9hFg7N123\", \"compledUser\": 4439,\"inWorkDate\":\"2024-11-22 14:45:55\"}",
   *     "comments": 0,
   *     "votes": 0,
   *     "depName": "Маркетинг",
   *     "creatorName": "John Doe",
   *     "respName": "John Doe",
   *     "teamNames": "+7-374-772-06-61,John Doe,Name,Name 2,Name1"
   * }
   */

  return <div className="cnt mobPDx TE">
    <h1 className="fx ac fsb">
      <span className="fx ac gap">
        <SvgIcon hash="editInPage" width="36"/>
        Редактирование задачи
        <Link to={`/task/${TID}`} title="Вернуться к задаче"><SvgIcon hash="back"/></Link>
      </span>
      <GoToMP/>
    </h1>
    <hr/>
    {
      todo ? <div>
          <div className="table">
            <div className="table-row">
              <span className="tr fwb table-cell table-txt">Отдел</span>
              <div className="table-cell mobOva">
                <ul className="fx gap.4 mobPDx">
                  <li>
                    <button
                      className="pr fx ac jc fs0 fwb btnDep btnCrtDep"
                      onClick={handleClcAddDep}
                    >+
                    </button>
                  </li>
                  <li>
                    {
                      addDep && (
                        <span className="pr">
                      <input
                        type="text" required autoFocus
                        placeholder="Название отдела"
                        className="addDep btnDep"
                        minLength="2" maxLength="80"
                        onInput={handleSetDep}
                        onKeyDown={e => {
                          if (e.key === 'Enter') handleSaveDep()
                        }}
                      />
                      <button
                        className="pa fs0 btn crttEnter"
                        onClick={handleSaveDep}
                      >+
                        <SvgIcon hash="enter" width="20"/>
                      </button>
                    </span>
                      )
                    }
                  </li>
                  {Array.isArray(departments) && departments.map((department) => (
                    <li key={department.id}>
                      <button
                        className={`fwb btnDep ${activeDepartmentId === department.id ? 'btnDepCl' : ''}`}
                        onClick={() => handleDepClick(department.id)}
                      >{department.title}
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="table-row my">
              <span className="tr fwb table-cell table-txt">Состояние</span>
              <div className="table-cell">
                <span className="fx gap2 TEBtnGrp">
                  {buttons.map((label, index) => (
                    <button
                      key={index}
                      className={`pr fwb btn ${
                        index === clickedIndex
                          ? "TEBtnGrpClOk" // Текущая нажатая кнопка
                          : clickedIndex !== null && index < clickedIndex
                            ? "TDBtnGrpCl" // Предыдущие кнопки
                            : ""
                      }`}
                      onClick={() => handleChangeState(index)}
                    >
                      {label}
                    </button>
                  ))}
                </span>
              </div>
            </div>
            <div className="table-row">
              <span className="tr fwb table-cell table-txt">Название</span>
              <div className="table-cell">
                <input
                  type="text" placeholder="Название задачи"
                  className="w1 fwb inp fs18" required
                  minLength="3"
                  defaultValue={todo.title}
                  onChange={handleNameChange}
                />
              </div>
            </div>
            <div className="table-row">
              <span className="tr fwb table-cell table-txt">Ответственный</span>
              <div className="table-cell">
                <DropDown options={usersSingle} cb={{
                  input_: handleSearchSingleUser,
                  setSearch_: handleSetSearchSingleUser,
                  setInvite_: handleSetInviteSingleUser,
                  setUsers_: setResp,
                  defVal: searchUserSingle,
                  setIds: todo.resp,
                }}/>
              </div>
            </div>
            <div className="table-row">
              <span className="tr fwb table-cell table-txt">Команда</span>
              <div className="table-cell">
                <DropDown options={usersMore} multiple={true} cb={{
                  input_: handleSearchMoreUser,
                  setSearch_: handleSetSearchMoreUser,
                  setInvite_: handleSetInviteMoreUser,
                  setUsers_: setTeam,
                  defVal: searchUserMore,
                  setIds: todo.json.team ?? null,
                }}/>
              </div>
            </div>
            <div className="table-row">
              <span className="tr fwb table-cell table-txt">Дедлайн</span>
              <div className="table-cell">
                <div className="fx gap1 fsb ac mobFdc mobAis">
                  <input
                    type="date"
                    className="fwb w1/3 inp"
                    // value={date}
                    defaultValue={todo.deadline}
                    onChange={handleDateChange}
                  />
                  <span className="fx fsb ac gap1">
                {(files && files.length > 0) &&
                  <Files files={files} setFiles={setFiles} needBtnDel={true} setDelFileFromSess={setDelFileFromTodo}
                         TID={TID}/>}
                    <label className="cp fx fsb ac gap1 addFile">
                  <span className="fx ac jc crttBtnAddFile">
                    <SvgIcon hash="file" width="20"/>
                  </span>
                  <InputFile handleFileChange={handleFileChange}/>
                  <span className="c6">Выбрать файл</span>
                </label>
              </span>
                </div>
              </div>
            </div>
            <div className="table-row">
              <span className="tr fwb table-cell table-txt">Описание</span>
              <span className="table-cell">
            <textarea
              className="w1"
              rows="8"
              defaultValue={todo.text}
              onChange={handleTextChange}
            ></textarea>
          </span>
            </div>
            <div className="table-row">
              <span className="table-cell">&nbsp;</span>
              <div className="table-cell">
            <span className="fx fdc gap fafs">
              {
                !checkForm.includes(1) &&
                <mark className="fx ac gap"><SvgIcon hash="info"/> Необходимо выбрать отдел</mark>
              }
              {
                !checkForm.includes(2) &&
                <mark className="fx ac gap"><SvgIcon hash="info"/> Необходимо ввести название</mark>
              }
              <button
                className="w1/2 cf fs fwb py1 mt2 mb crttBtnSave"
                onClick={handleSaveTodo}
                disabled={checkForm.length !== 2}
              >Сохранить задачу</button>
            </span>
              </div>
            </div>
          </div>
          {/* \ table*/}
        </div>
        : <h1 className="w1 my2 tc">Задача не обнаружена</h1>
    }
  </div>
}
export default TaskEditPage