// src/utils/DropDown.js
import React, { useState, useEffect, useRef } from 'react';
import settings from "../utils/settings";
import SvgIcon from "./SvgIcon";
import './Dropdown.css';

function Dropdown({
  options,
  cb={
    input_:()=>{},
    setSearch_:()=>{},
    setInvite_:()=>{},
    setUsers_:()=>{},
    defVal:'',
    setIds:null , //при редактировании задачи, устанавливаем активных пользователей (ответств./команда)
  },
  multiple=false,
  share=true
}) {
  const [isOpen, setIsOpen] = useState(false)
    , [selectedItems, setSelectedItems] = useState([])
    , [selectedNames, setSelectedNames] = useState('')
    , dropdownRef = useRef(null) // Реф для выпадающего списка
    , toggleDropdown = () => setIsOpen(!isOpen)
    , handleSelect = (option) => {
        let updatedItems;
        if (multiple) {
          // Режим мультивыбора
          if (selectedItems.includes(option)) {
            updatedItems = selectedItems.filter(item => item !== option);
          } else {
            updatedItems = [...selectedItems, option];
          }
        } else {
          // Режим одиночного выбора
          updatedItems = [option];
          setIsOpen(false); // Закрываем меню после выбора
        }
        setSelectedItems(updatedItems); // Обновляем состояние
        cb.setUsers_(updatedItems.map(item => item.id)); // Вызываем колбэк с обновленным значением
      }
    , isSelected = (option) => selectedItems.includes(option)
  ;
  // Закрытие меню при клике вне области
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('pointerdown', handleClickOutside);
    // Удаляем обработчик при размонтировании
    return () => {
      document.removeEventListener('pointerdown', handleClickOutside);
    };
    // if(cb.setIds){
    //   setSelectedItems()
    // }
  }, []);
  useEffect(() => {
    if(cb.setIds && !Array.isArray(cb.setIds))cb.setIds=[cb.setIds]
    if (cb.setIds && Array.isArray(cb.setIds)) {
      const preselectedUsers = options.filter(user => cb.setIds.includes(user.id));
      setSelectedItems(preselectedUsers);
    }
  }, [cb.setIds, options]);
  // Синхронизируем `selectedNames` при изменении `selectedItems`
  useEffect(() => {
    const names = selectedItems
      .map(item => (item.name === '' ? item.phone : item.name))
      .join(', ');
    setSelectedNames(names);
  }, [selectedItems]);

  return (
    <div className="pr dd" ref={dropdownRef}>
      <button className="cf fwb dd-toggle" onClick={toggleDropdown}>
        {selectedItems.length > 0 ? selectedNames : 'Выбрать из списка'}
      </button>
      {isOpen && (
        <div className="pd bx dd-menu">
          <small className="fx ac gap.4 lh1.2">
            <SvgIcon hash="info" width="20"/>
            При необходимости, введите номер телефона и нажмите кнопку «поделиться», чтобы отправить SMS с приглашением на номер пользователя,
            указанный Вами.
          </small>
          <span className="fx gap1 mb">
            <span className="pr fx ac">
              <input
                type="text"
                className="ddInpSrc"
                name="phone"
                placeholder="Поиск человека (имя, тел.)"
                minLength="2" maxLength="80"
                onChange={cb.input_}
                defaultValue={cb.defVal}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.code === 'Enter') {
                    e.preventDefault();
                    cb.setSearch_(e); // Вызов функции, связанной с кнопкой
                  }
                }}
                onPaste={(e) => {
                  setTimeout(() => {
                    cb.input_(e); // Задержка для обработки вставленного текста
                  }, 0);
                }}
                onInput={(e) => {
                  if (e.nativeEvent.inputType === 'insertReplacementText' || e.nativeEvent.inputType === 'insertFromAutoComplete') {
                    cb.input_(e);
                  }
                }}
              />
              <button
                className="pa fs0 btn ddBtnSrc"
                title="Поиск"
                onClick={cb.setSearch_}
              >Поиск
                <SvgIcon hash="lens" width="30"/>
              </button>
            </span>
            {
              share && (
                <button
                  className="fs0 ddSrc"
                  title="Поделиться"
                  onClick={cb.setInvite_}
                >Поделиться
                  <SvgIcon hash="share" width="30"/>
                </button>
              )
            }
          </span>
          <div className="ova">
            {options.map((option) => (
              <span
                key={option?.id}
                className="dd-item"
                onClick={() => handleSelect(option)}
              >
                {(option.avatar) ? (
                    <picture>
                      {/*<source
                        srcSet={`${process.env.REACT_APP_API_URL_IMG + option.id + '/' + option.avatar + '_t'}.avif`}
                        type="image/avif"
                      />*/}
                      <source
                        srcSet={`${settings.REACT_APP_API_URL_IMG + option.id + '/' + option.avatar + '_t'}.webp`}
                        type="image/webp"
                      />
                      <img
                        src={`${settings.REACT_APP_API_URL_IMG + option.id + '/' + option.avatar + '_t'}.jpg`}
                        alt="avatar"
                        width="36" loading="lazy"
                        className="br50 ava"
                      />
                    </picture>
                  )
                  :
                  (<span className="br50 psdImg"/* role="img" aria-label="User icon"*/>&#128100;</span>)
                }
                <span className="dd-nm">
                  <q>{(option?.name === '') ? option?.phone : option?.name}</q>
                  {(option?.name !== '') ? <small>{option?.phone}</small> : ''}
                </span>
                <span className={`fs0 dd-mark${isSelected(option) ? ' dd-markCl' : ''}`}>+</span>
              </span>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default Dropdown;