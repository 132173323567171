// src/pages/TOSPage.js
// Страница, которая выполняет функционал приглашения по URL пользователя
import React, {useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import useFetch from "../utils/useFetch";

const IPage = () => {
  const nav=useNavigate()
      , [paramH, setParamH] = useState('')
      , { setFetch: getToken, dataResponse: getDataToken } = useFetch('auth/getToken', '1')
      , { setFetch: getCheckInviteBySms, dataResponse: getCheckInviteBySmsData } = useFetch('user/getCheckInviteBySms',paramH)
  ;
  useEffect(() => {
    getToken(); // Выполняем запрос
  }, []);

  // Отслеживаем изменения в getDataToken и выводим его, когда данные обновятся
  useEffect(() => {
    if (
      getDataToken
      && getDataToken.s
      && getDataToken.s===1
      && getDataToken.d
      && getDataToken.d!==''
    ) {
      localStorage.setItem('hash',getDataToken.d)
    }
  }, [getDataToken]); // Запускается при изменении getDataToken
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
        , h = urlParams.get('h') // Получаем значение параметра 'h' (hash)
    ;
    if(h) {
      setParamH(h);
    }else{
      alert('Отсутствует параметр. Проверьте ссылку.')
      throw new Error('No hash_')
    }
    if (paramH&&!window.tmp_45645) {
      // window.tmp_45645='1';
      localStorage.setItem('h', h);
      if(paramH.length>5 && localStorage.getItem('hash'))getCheckInviteBySms();
    }
  }, [paramH, getCheckInviteBySms]);
  useEffect(()=>{
    if(getCheckInviteBySmsData&&getCheckInviteBySmsData.d) {
      const {s, d} = getCheckInviteBySmsData;
      if (s === 1 && d !== '') {
        const messages = {
          PRONE_EXIST_GO_TO_AUTH: {
            alert: 'Мы нашли Ваш телефон в нашей базе. Авторизуйтесь на нашем сайте.',
            clearLocalStorage: true,
          },
          PRONE_NO_EXIST_GO_TO_REG: {
            alert: 'Зарегистрируйтесь на нашем сайте. Затем Вы сможете попасть к задачам, в которых Вы участвуете.',
          },
        };

        const action = messages[d];
        if (action) {
          if (action.clearLocalStorage) {
            localStorage.removeItem('h');
          }
          alert(action.alert);
          nav('/auth', { replace: true });
        }
      }
    }
  },[getCheckInviteBySmsData]);

  return (
    <div className="cnt">
      <h1 className="py1">Перенаправление...</h1>
      <p>Сейчас Вы будете перенаправлены.</p>
    </div>
  );
};

export default IPage;