const formatPhone=(phone)=>{
  // Удаляем все, кроме цифр
  const digits = phone.replace(/\D/g, '');

  // Проверяем, что у нас достаточно цифр для российского номера телефона
  if (digits.length === 11 && digits.startsWith('8')) {
    // Заменяем первую цифру (8) на 7, так как это стандартный формат
    return digits.replace(/^8/, '+7')
      .replace(/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/, '$1-$2-$3-$4-$5');
  } else if (digits.length === 11 && digits.startsWith('7')) {
    // Если номер уже начинается с 7, просто форматируем его
    return `+${digits.replace(/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/, '$1-$2-$3-$4-$5')}`;
  } else {
    return false;
  }
}

export const formatPhoneNumber = (number) => {
  // Убираем все нецифровые символы, кроме "+"
  const cleanNumber = number.replace(/[^\d]/g, "");

  // Если пользователь начал ввод с неправильного символа
  if (!cleanNumber.startsWith("7")) {
    return "+7"; // Начинаем с "+7"
  }

  // Добавляем "+7-" перед вводом остальных цифр
  let formatted = "+7";

  if (cleanNumber.length > 1) {
    formatted += "-" + cleanNumber.slice(1, 4); // Код региона
  }
  if (cleanNumber.length > 4) {
    formatted += "-" + cleanNumber.slice(4, 7); // Первая часть номера
  }
  if (cleanNumber.length > 7) {
    formatted += "-" + cleanNumber.slice(7, 9); // Вторая часть номера
  }
  if (cleanNumber.length > 9) {
    formatted += "-" + cleanNumber.slice(9, 11); // Третья часть номера
  }

  return formatted;
};

export default formatPhone