// src/pages/Users/ManageUsersPage.js
// Страница управления пользователями

import React, {useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import useFetch from "../../utils/useFetch";
import SvgIcon from "../../components/SvgIcon";
import GoToMP from "../../components/GoToMP";
import {formatPhoneNumber} from "../../utils/formatPhone";
import Image from "../../components/Image";
import settings from "../../utils/settings";

import './ManageUsers.css'

const ManageUsers = () => {
  const nav= useNavigate()
      , [users, setUsers] = useState([])
      , [cachedUsers, setCachedUsers] = useState([])
      , [activeBtn, setActiveBtn] = useState(50)
      , [isCache, setIsCache] = useState(false)
      , [isAdmin, setIsAdmin] = useState(false)
      , [isSuper, setIsSuper] = useState(false)
      , [isSuperTF, setIsSuperTF] = useState(true)
      , [pp, setPp] = useState(false) // popup
      , [ppEditData, setPpEditData] = useState([]) // popup edit data
      , [searchWordUser, setSearchWordUser]=useState('')
      , { setFetch: getCheckRights, dataResponse: getCheckRightsData} = useFetch('user/getCheckRights','1')
      , { setFetch: getForUM, dataResponse: getForUMData }            = useFetch('user/getForUM',activeBtn)
      , { setFetch: setSaveFromUM, dataResponse: setSaveFromUMData }            = useFetch('user/setSaveFromUM',null)
      , { setFetch: getUserSearch, dataResponse: getUserSearchData }   = useFetch('user/getSearch',null)
      , { setFetch: getCompany, dataResponse: getCompanyData }   = useFetch('company/getCompany',null)
      , edit = (e)=>{
          setPp(true);
          setPpEditData(e) // obj — single user
        }
      , closePp=(e)=>{
          if(
            e.target.classList.contains('popup')
            || e.target.classList.contains('popupClose')
          ){
            setPp(false);
            setPpEditData([])
          }
        }
      , handleClickBtn = (e) => {
          const value = Math.floor(e.target.getAttribute("data-c")); // Получаем значение data-c
          setActiveBtn(value); // Устанавливаем активную кнопку
          getForUM(value)
        }
      , handleChange = (e) => {
          let {name,value}=e.target;
          if(name==='phone'){
            value=formatPhoneNumber(value)
          }
          setPpEditData({
            ...ppEditData,
            [name]: value, // Обновляем поле по имени
          });
        }
      , saveUser=()=>{
          setSaveFromUM(ppEditData);
        }
      , handleChangeSearch=(e)=>{
          const val=e.target.value
          setSearchWordUser(val)
        }
  ;
  useEffect(() => {
    getCheckRights()
    getForUM()
  }, []);
  useEffect(() => {
    if(getCheckRightsData && getCheckRightsData.s===1){
      if(getCheckRightsData.d === '0') {
        alert('1. У Вас нет прав доступа к данной странице.')
        nav('/')
      }
      setIsAdmin(parseInt(getCheckRightsData.d) > 0)
      setIsSuper(getCheckRightsData.d === '2')
    }
  }, [getCheckRightsData]);
  useEffect(() => {
    if(getForUMData && getForUMData.s===1){
      if(getForUMData.d){
        const j=JSON.parse(getForUMData.d)
        setUsers(j)
        if(!isCache) {
          setIsCache(true)
          setCachedUsers(j)
        }
      }
    }
  }, [getForUMData]);
  useEffect(() => {
    if(setSaveFromUMData && setSaveFromUMData.s===0 && setSaveFromUMData.d){
      alert(setSaveFromUMData.d)
    }
    if(setSaveFromUMData && setSaveFromUMData.s===1 && setSaveFromUMData.d && setSaveFromUMData.d.startsWith('Данные у')){
      alert(setSaveFromUMData.d);
      getForUM()
    }
  }, [setSaveFromUMData]);
  useEffect(() => {
    if(getUserSearchData && getUserSearchData.s===1 && getUserSearchData.d){
      setUsers(JSON.parse(getUserSearchData.d))
    }
    if(getUserSearchData && getUserSearchData.s===0 && getUserSearchData.d){
      alert(getUserSearchData.d)
    }
  }, [getUserSearchData]);

  useEffect(() => {
    console.log(searchWordUser.length)
    if(searchWordUser.length===0){
      setUsers(cachedUsers)
    }
  }, [searchWordUser]);

  useEffect(() => {
    if(isSuper && isSuperTF){
      setIsSuperTF(false)
      getCompany()
    }
  }, [isSuper]);

  /**
   * {
   *     "id": 4,
   *     "name": "Name",
   *     "phone": "+7-00-111-22-33",
   *     "email": "gg@gg.com",
   *     "rights": "0"
   *     "state": "1" (may: 0,1,2)
   * }
   */
  return isAdmin ? <div className="cnt mobPDx USRS">
    <h1 className="fx ac fsb gap">
      <span className="fx ac gap">
        <SvgIcon hash="users" width="36"/>
        Управление пользователями
      </span>
      <GoToMP/>
    </h1>
    {
      users ?
        <>
          <span className="fx ac gap my2">
            <input
              type="text" placeholder="Найти пользователя"
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.code === 'Enter') {
                  e.preventDefault();
                  getUserSearch([searchWordUser,'UM']);
                }
              }}
              onChange={handleChangeSearch}
            />
            <button
              className="fs0 btn"
              onClick={()=>getUserSearch([searchWordUser,'UM'])}
            >
              Поиск
              <SvgIcon hash="lens" width="30"/>
            </button>
          </span>
          <span className="fx gap1 my USRSBtns">
            <button onClick={handleClickBtn} className={`btn ${activeBtn === 50 ? "USRSBtnCl" : ""}`} data-c="50">50</button>
            <button onClick={handleClickBtn} className={`btn ${activeBtn === 100 ? "USRSBtnCl" : ""}`} data-c="100">100</button>
            <button onClick={handleClickBtn} className={`btn ${activeBtn === 500 ? "USRSBtnCl" : ""}`} data-c="500">500</button>
            <button onClick={handleClickBtn} className={`btn ${activeBtn === 1000 ? "USRSBtnCl" : ""}`} data-c="1000">1000</button>
          </span>
          <ul className="my2 table">
            <li>
              <span className="mobDn"><strong>ID</strong></span>
              <span className="mobDn"><strong>Имя</strong></span>
              <span className="mobDn"><strong>Телефон</strong></span>
              <span className="mobDn"><strong>Email</strong></span>
              <span className="mobDn"><strong>Права</strong></span>
              <span><strong>Ред.</strong></span>
            </li>
            {
              users.map(e=> {
                return <li key={e.id}>
                  <span>{e.id}</span>
                  <span>{(e.name!=='')? e.name : '—'}</span>
                  <span>{e.phone}</span>
                  <span>{(e.email!=='')? e.email : '—'}</span>
                  <span>{(e.rights==='1')?<SvgIcon hash="star"/>:''}</span>
                  <span>
                    <button className="btn"
                      onClick={()=>{edit(e)}}
                    >
                      <SvgIcon hash="edit"/>
                    </button>
                  </span>
                </li>
              })
            }
          </ul>
          {
            (pp && ppEditData) && <div className="pf in0 z11 popup" onClick={closePp}>
              <div className="popupC bgf pd br">
                <h2 className="fx fsb gap mb">
                  <span className="fx ac gap.5">
                    <SvgIcon hash="edit"/>
                    Редактирование пользователя <small>{ppEditData.id}</small>
                  </span>
                  <button className="fs0 btn popupClose"
                          onClick={closePp}
                  >Close
                  </button>
                </h2>
                <span className="fx gap1">
                  {
                    (ppEditData.avatar && ppEditData.avatar !== '') ?
                      <Image class_="mb"
                             URL={settings.REACT_APP_API_URL_IMG + ppEditData.id + '/' + ppEditData.avatar + '_t'}/>
                      : ''
                  }
                  <span className="fx fdc gap1 w1 mb">
                    <label>Фамилия, имя:</label>
                    <input
                      onChange={handleChange}
                      name="name"
                      value={ppEditData.name}/>
                  </span>
                </span>
                <span className="fx fdc gap1">

                  <label>Телефон:</label>
                  <input
                    onChange={handleChange}
                    name="phone"
                    value={ppEditData.phone}/>
                  <label>Email:</label>
                  <input
                    onChange={handleChange}
                    name="email"
                    value={ppEditData.email}/>
                </span>
                <h5 className="mt">Роль:</h5>
                <span className="fx fsb gap1 my">
                  <label className="radio-label">
                    <input
                      type="radio"
                      value="1"
                      name="rights"
                      checked={ppEditData.rights === '2'}
                      onChange={handleChange}
                    />
                    <span className="custom-radio"></span>
                    Super
                  </label>
                  <label className="radio-label">
                    <input
                      type="radio"
                      value="1"
                      name="rights"
                      checked={ppEditData.rights === '1'}
                      onChange={handleChange}
                    />
                    <span className="custom-radio"></span>
                    Администратор
                  </label>
                  <label className="radio-label">
                    <input
                      type="radio"
                      value="0"
                      name="rights"
                      checked={ppEditData.rights === '0'}
                      onChange={handleChange}
                    />
                    <span className="custom-radio"></span>
                    Пользователь
                  </label>
                </span>
                <h5>Статус учётной записи:</h5>
                <span className="fx fsb gap1 my">{/*0 — удалён, 1 — активен, 2 — заблокирован*/}
                  <label className="radio-label">
                    <input
                      type="radio"
                      value="1"
                      name="state"
                      checked={ppEditData.state === '1'}
                      onChange={handleChange}
                    />
                    <span className="custom-radio"></span>
                    активен
                  </label>
                  <label className="radio-label">
                    <input
                      type="radio"
                      value="0"
                      name="state"
                      checked={ppEditData.state === '0'}
                      onChange={handleChange}
                    />
                    <span className="custom-radio"></span>
                    удалён
                  </label>
                  <label className="radio-label">
                    <input
                      type="radio"
                      value="2"
                      name="state"
                      checked={ppEditData.state === '2'}
                      onChange={handleChange}
                    />
                    <span className="custom-radio"></span>
                    заблокирован
                  </label>
                </span>
                <button className="pd bgsky cf fwb tu" onClick={saveUser}>Сохранить</button>
              </div>
            </div>
          }
        </>
        : <h1 className="w1 my2 tc">Пользователей не обнаружено</h1>
    }
  </div> : <h1 className="w1 my2 tc">Нет прав доступа</h1>
}

export default ManageUsers