const changeStyle=(value=0,auto=false)=>{
  // auto — проверка выполняется автоматически, без переключения пользователем на странице профиля.
  // Убираем все классы фона, чтобы оставить только один
  const classes = ['style-0', 'style-1', 'style-2', 'style-3'];
  classes.forEach(className => document.body.classList.remove(className));
  const lsSt=localStorage.getItem('style');
  if(auto){ // initial state
    document.body.classList.add(`style-${lsSt}`);
  }
  if(value!=='')localStorage.setItem('style', String(value));
  // Добавляем нужный класс на body в зависимости от выбранной кнопки
  if (!auto && value !== null) {
    document.body.classList.add(`style-${value}`);
  }
}

export default changeStyle;