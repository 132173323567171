// src/pages/Home/HomePage.js
import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {setDepartments} from '../../features/departmentSlice';
import useFetch from "../../utils/useFetch";
import {formatDateDifference} from "../../utils/formatDateToD";
import SvgIcon from "../../components/SvgIcon";
import {Link} from "react-router-dom";
import {isObject} from "../../utils/checkIsObj";

import './HomeMobile.css'

const HomeMobilePage = () => {
  const dispatch = useDispatch()
    , departments = useSelector((state) => state.departments.departmentsList)
    // , [isVoted, setIsVoted] = useState(false)
    , [isNew, setIsNew] = useState(false)
    , [actvBtn, setActvBtn] = useState(0)
    , [isCompl, setIsCompl] = useState(false)
    , [isVotedF, setIsVotedF] = useState(false)
    , [activeDepartmentId, setActiveDepartmentId] = useState(null)
    , [idDeleted, setIdDeleted] = useState(0)
    , [idVote, setIdVote] = useState(0)
    , [todos8, setTodos8] = useState([]) // To_do, которые в работе
    , [todosNew, setTodosNew] = useState([])
    , [todosCompleted, setTodosCompleted] = useState([])
    , [filteredTasks, setFilteredTasks] = useState([])
    , [searchParams, setSearchParams] = useState({
        searchParams:'',// поисковое слово
        limit:    '50', // сколько максимум выводить задач при поиске
        state:    '2', // состояние 0 — удалена, 1 — новая, 2 — в работе, 3 — завершена
        dateFrom: '',  // дата «с» new Date(new Date().setDate(new Date().getDate() - 7)).toISOString().split('T')[0]
        dateTo:   '',  // дата «по» new Date().toISOString().split('T')[0]
        orderBy:  '1', // 0-ask|1-desc
        creator:  '',  // создатель
        team:     '',  // команда
        resp:     '',  // ответственный
        sort:     '1', // сортировка 0 — title, 1 — id, 2 — deadline
        dep:      '',  // отдел
        needComm: '1', // нужны ли комментарии (0—нет,1—да) — добавляет подсчёт комментариев
        needVote: '0', // нужно ли кол-во голосов (0—нет,1—да) — добавляет подсчёт голосов | если «да», то упорядочивается по кол-ву голосов, от > к <
      })
    , { setFetch: getDepartments, dataResponse: getDepartmentsData } = useFetch('todo/getDepartments','2')
    , { setFetch: getSearch8, dataResponse: getSearch8Data }           = useFetch('todo/getSearch',new URLSearchParams(searchParams).toString() )
    , { setFetch: getSearchNew, dataResponse: getSearchNewData }           = useFetch('todo/getSearch',new URLSearchParams(searchParams).toString() )
    , { setFetch: getSearchCompleted, dataResponse: getSearchCompletedData }= useFetch('todo/getSearch',new URLSearchParams(searchParams).toString() )
    , { setFetch: setVote, dataResponse: setVotedData }= useFetch('todo/setVote', idVote )
    , { setFetch: setStateTodoById, dataResponse: setStateTodoByIdData }= useFetch('todo/setStateTodoById', null ) // Delete to_do
    , { setFetch: getWhatIVote, dataResponse: getWhatIVoteData }= useFetch('todo/getWhatIVote', '1' )
    , departmentsWithAll = [{ id: -1, title: "Все", count:todos8.length }, ...departments]
    , UID=localStorage.getItem('UID') ?? 0
    , handleDepClickInner = (departmentId) => {
        // Если ID -1, сбрасываем фильтр
        if (departmentId === -1) {
          setFilteredTasks(todos8); // tasks — это полный массив задач
        } else {
          const filtered = todos8.filter((task) => {
            const jsonParsed = task.json;
            return jsonParsed.dep === departmentId;
          });
          if(filtered.length>0) {
            setFilteredTasks(filtered)
          }
        }
        setActiveDepartmentId(departmentId); // Активируем текущую кнопку
      }
    , handleDepClick = depId => {
        setActiveDepartmentId(depId);
        handleDepClickInner(depId)
        setActvBtn(1)
      }
    , handleSetVote=(id)=>{
        const el=document.querySelectorAll('div.todoNewCl')
        if(el && el.length>0){
          el.forEach(q=>{
            q.classList.remove('todoNewCl')
          })
        }
        setVote(id)
        setIdVote(id)
        const newEl=document.querySelector(`[data-i="${id}"]`)
        if(newEl)newEl.classList.add('todoNewCl')
      }
    , handleSetDeleteTodo=(id)=>{
        setStateTodoById([id,0])
        setIdDeleted(id)
      }
    , handleBtnClck=(e)=>{
        setActvBtn(e)
      }
  ;
  // Отделы
  useEffect(() => {
    // Вызываем setNewDepartment для запроса данных об отделах с сервера
    getDepartments();
    getWhatIVote();
  }, []);
  useEffect(() => {
    // Когда ответ получен, обновляем Redux
    if (getDepartmentsData) {
      const dep=JSON.parse(getDepartmentsData.d)
      dispatch(setDepartments(dep));
      setActiveDepartmentId(-1)
    }
  }, [dispatch, getDepartmentsData]);
  // \ Отделы
  // 8
  useEffect(() => {
    getSearch8();
  }, []);
  useEffect(() => {
    if(getSearch8Data&&getSearch8Data.s){
      if (getSearch8Data.s===1&&getSearch8Data.d.startsWith('[{')) {
        const j =JSON.parse(getSearch8Data.d)
        if(j.length>0){
          j.forEach(e=>{
            e.json=JSON.parse(e.json)
          })
          setTodos8(j)
        }
        // // Подтягиваем данные для новых
        // if(!window.tmp345){
        //   window.tmp345=true;
        //   getSearchNew();
        // }
      }
      // if (getSearch8Data.s===0){
      //   alert(getSearch8Data.d)
      // }
    }
  }, [getSearch8Data]);
  useEffect(() => {
    setFilteredTasks(todos8)
  }, [todos8]);
  useEffect(() => {
    if(!isNew){
      setSearchParams(prevParams => ({ // устанавливаем параметры поискадля получения новых задач (с подсчётом голосов)
        ...prevParams,
        state: '1', // Новые
        limit: '50', // Новый limit
        needComm: '0', // отмена подсчёта кол-ва комм.
        needVote: '1', // добавим подсчёт голосов и упорядочим по ним
      }));
    }
    if(searchParams.state==='1'&&!getSearchNewData&&!isNew){
      setIsNew(true)
      getSearchNew()
    }
  }, [searchParams,getSearchNewData]);
  useEffect(() => {
    if(!isCompl){
      setSearchParams(prevParams => ({ // устанавливаем параметры поискадля получения новых задач (с подсчётом голосов)
        ...prevParams,
        state: '3', // Заверш.
        limit: '50',
        needComm: '0', // отмена подсчёта кол-ва комм.
        needVote: '0', // добавим подсчёт голосов и упорядочим по ним
      }));
    }
    if(searchParams.state==='3'&&!getSearchCompletedData&&!isCompl){
      setIsCompl(true);
      getSearchCompleted()
    }
  }, [searchParams,getSearchCompletedData]);
  // \ 8
  // Новые
  useEffect(() => {
    if(getSearchNewData&&isObject(getSearchNewData)&&getSearchNewData.s){
      // Получаем новые
      if (getSearchNewData.s===1&&getSearchNewData.d.startsWith('[{')) {
        setTodosNew(JSON.parse(getSearchNewData.d))
      }
      // \ Получаем новые.
    }
  }, [getSearchNewData]);
  // \ Новые
  // завершённые
  useEffect(() => {
    // Получаем завершённые
    if(getSearchCompletedData&&isObject(getSearchCompletedData)&&getSearchCompletedData.s){
      if(getSearchCompletedData&&getSearchCompletedData.s===1&&getSearchCompletedData.d.startsWith('[{')){
        setTodosCompleted(JSON.parse(getSearchCompletedData.d))
      }
    }
    // \ Получаем завершённые
  }, [getSearchCompletedData]);
  // \ завершённые
  useEffect(() => {
    if(idVote>0&&!isVotedF){
      setIsVotedF(true)
      setVote()
    }
    if(setVotedData){
      setTodosNew((prevTodos) =>
        prevTodos.map((todo) =>
          todo.id === idVote ? { ...todo, vote: todo.vote + 1 } : todo
        )
      );
    }
  }, [idVote,setVotedData]);
  useEffect(() => {
    if(
        setStateTodoByIdData
        &&isObject(setStateTodoByIdData)
        &&setStateTodoByIdData.s===1
        &&idDeleted
    ){
      setTodosCompleted((prevTodos) => prevTodos.filter((todo) => todo.id !== idDeleted));
      setIdDeleted(0)
    }
  }, [setStateTodoByIdData,idDeleted]);


  useEffect(() => {
    if(getWhatIVoteData && getWhatIVoteData.s===1 && getWhatIVoteData?.d.length>0){
      const j =JSON.parse(getWhatIVoteData.d);
      if(j && j.length>0){
        const it=setInterval(()=>{
          const el=document.querySelectorAll("[data-i]")
          if(el && el.length>0){
            clearInterval(it)
            el.forEach(z=>{
              const dataI = Math.floor(z.dataset.i);
              if (j.includes(dataI)) {
                z.classList.add('todoNewCl');
                z.title="Вы проголосовали за эту задачу"
              }
            })
          }
        },1e3)
      }
    }
  }, [getWhatIVoteData]);
  useEffect(() => {
    if(setVotedData){
      setSearchParams(prevParams => ({ // устанавливаем параметры поиска для получения новых задач (с подсчётом голосов)
        ...prevParams,
        state: '1', // Новые
        limit: '50', // Новый limit
        needComm: '0', // отмена подсчёта кол-ва комм.
        needVote: '1', // добавим подсчёт голосов и упорядочим по ним
      }));
      getSearchNew();
      // if(setVotedData.s===1 && setVotedData?.d.startsWith('OK_UH'))alert(setVotedData?.d);
      if(setVotedData.s===0 && setVotedData?.d)alert(setVotedData?.d);
    }
  }, [idVote,setVotedData]);
  return (
    <div className="cnt1280">

      <span className="fx jc my">
        <Link to="create-task" className="fx ac gap c0 tu">
          <SvgIcon hash="addTodo" width="26"/>
          создать задачу
        </Link>
      </span>
      {
        (Array.isArray(todosNew) && (todosNew.length > 0) && departmentsWithAll) ? <ul className="fx gap.4 HPul">
          {
            Array.isArray(departmentsWithAll) && departmentsWithAll.map((department) => (
              <li key={department.id}>
                <button
                  className={`pr fwb btnDep btnDepWC ${activeDepartmentId === department.id ? 'btnDepCl' : ''}${department.id === -1 ? ' btnDepAll' : ''}`}
                  onClick={() => handleDepClick(department.id)}
                >{department.title}{(department.count > 0) ?
                  <small className="pa br50 bx fwb">{department.count}</small> : ''}
                </button>
              </li>
            ))
          }
        </ul> : <h2 className="w1 tc pd">Задач «в работе» не обнаружено.</h2>
      }
      <span className="fx gap.8 fsa btnGrp">
        <button
          className={`pr mt2 fwb fs ${actvBtn === 0 ? 'btnGrpCl' : ''}`}
          onClick={() => {
            handleBtnClck(0)
          }}
        >Новые</button>
        <button
          className={`pr mt2 fwb fs ${actvBtn === 1 ? 'btnGrpCl' : ''}`}
          onClick={() => {
            handleBtnClck(1)
          }}
        >В работе</button>
        <button
          className={`pr mt2 fwb fs ${actvBtn === 2 ? 'btnGrpCl' : ''}`}
          onClick={() => {
            handleBtnClck(2)
          }}
        >Завершённые</button>
      </span>

      <section>
        {/*Новые*/}
        <div className={`fx fw gap10p mobFdc py1 w1 hpGd hpSldr ${actvBtn === 0 ? 'hpSldrCl' : ''}`}>
          {
            (todosNew.length !== 0) ? (todosNew.map(e => (
              /**
               * {\"id\":246,\"title\":\"Название\",\"datetime\":\"2024-10-16 11:42:12\",\"UID\":4439,\"deadline\":\"2024-10-25\",\"vote\":1,"resp":4439,"resp_name":"Joker"}
               */
              <div key={e.id} data-i={e.id} className="pr fx fdc fsb br bx hpGdd">
                <Link to={`/task/${e.id}`}>
                  <h3>{e.title}</h3>
                </Link>
                {/*<p>{e.description || '…'}</p>*/}
                <span className="fx ac fsb gap.4 pd hpGdSpn">
                  <h4 className="fwn">
                    {e.resp_name || <small className="cg">нет ответственного</small>}
                  </h4>
                  <span className="fx ac gap1">
                    {(e.comm && e.comm > 0) ?
                      <span className="fx ac gap.2 br hpGdSpnBg"><SvgIcon hash="message" width="18"/> {e.comm}</span>
                      :
                      <span>&nbsp;</span>
                    }
                    <span className="hpNTm">время {formatDateDifference(new Date(e.datetime), true)}</span>
                    <span
                      className="hpInW">
                      <button
                        className="fx ac gap btnFinger"
                        onClick={() => handleSetVote(e.id)}
                      >
                        <SvgIcon hash="finger" class_="finger" width="16"/>{(e.vote === 0) ?
                        <q className="cgl">0</q> : e.vote}
                      </button>
                    </span>
                  </span>
                </span>
              </div>
            ))) : <h1 className="w1 tc cg my2">Новых задач пока нет...</h1>
          }
        </div>
      </section>

      <section>
        {/*В работе*/}
        <div className={`fx fw gap10p mobFdc w1 py1 hpGd hpSldr ${actvBtn === 1 ? 'hpSldrCl' : ''}`}>
          {
            (filteredTasks.length !== 0) ? (filteredTasks.map(e => (
              /**
               * {
               *     "id": 264,
               *     "title": "yththth",
               *     "datetime": "2024-11-18 18:27:05",
               *     "state": 1,
               *     "UID": 4439,
               *     "description": "sfgsfgfgdfgdfggfdg",
               *     "updated": "2024-11-18 18:27:05",
               *     "deadline": "2024-11-30",
               *     "resp": 4439,
               *     "resp_name": "Вася Пупкин" (имя ответственного)
               *     "json": "{\"files\":[[\"20241118_031157_126_673b5cc11eaad.jpg\",\"902.92 KB\"],[\"20241118_031157_128_673b5cc11f273.zip\",\"2.59 MB\"],[\"20241118_031157_130_673b5cc11fb2b.zip\",\"984.86 KB\"]],\"team\":[4438],\"dep\":2,\"tmp_tid\":\"tWIbySuJ1cvp\"}"
               * }
               * если needComm=1, то в ответе прилетает также `"comm":5`
               */
              <div key={e.id} className="pr fx fdc fsb br bx hpGdd">
                <Link to={`/task/${e.id}`}>
                  <h3>{e.title}</h3>
                </Link>
                {/*<p>{e.description || '…'}</p>*/}
                <span className="fx ac fsb gap.4 pd hpGdSpn">
                  <h4 className="fwn">
                    {e.resp_name || <small className="cg">нет ответственного</small>}
                  </h4>
                  <span className="fx ac gap1">
                    {(e.comm && e.comm > 0) ?
                      <span className="fx ac gap.2 br hpGdSpnBg"><SvgIcon hash="message" width="18"/> {e.comm}</span>
                      :
                      <span>&nbsp;</span>
                    }
                    {(() => {
                      const dd = formatDateDifference(e.deadline)
                      if (parseInt(dd) > 1) {
                        return <span
                          className="hpInW">
                            <q>в работе {formatDateDifference(new Date(e.json?.inWorkDate ?? e.datetime), true)}</q>
                          </span>
                      } else {
                        return <span className="hpInW mark">дедлайн {dd}</span>
                      }
                    })()}
                  </span>
                </span>
              </div>
            ))) : <h1 className="w1 tc cg my2">Задач в работе пока нет...</h1>
          }
        </div>
      </section>

      <section>
        {/*Завершённые*/}
        <div className={`fx fw gap10p mobFdc w1 py1 hpGd hpSldr ${actvBtn === 2 ? 'hpSldrCl' : ''}`}>
          {
            (todosCompleted.length !== 0) ? (todosCompleted.map(e => (
              /**
               * {"id":284,"title":"Разработка дизайна","datetime":"2024-11-20 13:20:00","state":3,"UID":4437,"description":"Разработка дизайна для нового интерфейса","updated":"2024-11-22 21:30:47","deadline":"2024-12-14","resp":4438,"compledUserId":null,"compledDate":null,"compledUserName":null}
               */
              <div key={e.id} className="pr fx fdc fsb br bx hpGdd">
                <Link to={`/task/${e.id}`}>
                  <h3>{e.title}</h3>
                </Link>
                {/*<p>{e.description || '…'}</p>*/}
                <span className="fx ac fsb gap.4 pd hpGdSpn">
                  <h4 className="fwn">
                    {e.compledUserName || <small className="cg">—</small>}
                  </h4>
                  <span>
                    {
                      (Math.floor(UID) === Math.floor(e.UID))
                        ? <button
                          onClick={() => handleSetDeleteTodo(e.id)}
                          className="fx ac gap"
                        >
                          Удалить <SvgIcon hash="remove" class_="remove" width="16"/>
                        </button> : ''
                    }
                  </span>
                  <span className="hpInW">завершена {formatDateDifference(e.compledDate ?? e.datetime)}</span>
                </span>
              </div>
            ))) : <h1 className="w1 tc cg my2">Завершённых задач пока нет...</h1>
          }
        </div>
      </section>
      <div className="mb">&nbsp;</div>
    </div>
  );
};

export default HomeMobilePage;