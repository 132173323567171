import React from "react";
import getFileType from "../utils/getFileType";
import settings from "../utils/settings";
import SvgIcon from "./SvgIcon";

const Files=({
 files/*files array []*/,
 setFiles=()=>{}/*useState*/,
 setDelFileFromSess/*useFetch*/=()=>{},
 needBtnDel=false,
  class_='',
  text=true,
  TID/*to_do ID*/,
})=>{
  if (files&&files?.length>0) {
    const handleRemoveFile=(f) => {
        if(!TID) {
          setDelFileFromSess(f);
        }else{
          setDelFileFromSess([TID, f]);
        }
        setFiles(prevFiles => prevFiles.filter(file => file[0] !== f));
      }
    ;
    return (
      <span className={`fx fdc gap.4 ${class_}`}>
        {/*{(text && <strong>Файлы:</strong>)}*/}
        {files &&
          (
            <ul className="fx fw gap1">
              {files.map((file, index) => {
                const fileType = getFileType(file[0]);
                return (
                  <li key={index} className="pr crttFls">
                    {/*<strong>Имя файла:</strong> {file[0]} <br />*/}
                    <button
                      title="Скачать файл"
                      onClick={() => {
                        window.open(`${settings.REACT_APP_API_URL}/public/files/${file[0]}`)
                      }}
                      className="pa btn fs0 crttFlsB"
                    >
                      Download file
                      <SvgIcon hash="download" width="16" class_="br50 bgf p.1"/>
                    </button>
                    {
                      needBtnDel && <button
                        title="Удалить файл" className="pa btn fs0"
                        onClick={() => handleRemoveFile(file[0])}
                      >
                        Delete file
                        <SvgIcon hash="remove" width="16" class_="br50 bgf p.1"/>
                      </button>
                    }
                    <small>
                      {
                        (() => {
                          const vecSize = file[1].split(' '); // Пример: "900.45 KB"
                          const sizeNumber = parseInt(Math.ceil(parseFloat(vecSize[0]))); // Округляем число
                          return `${sizeNumber} ${vecSize[1]}`; // Возвращаем строку с округленным значением и единицей измерения
                        })()
                      }
                    </small>{/*размер файла*/}
                    {
                      (() => {
                        if (fileType === 'image') {
                          return <img src={`${settings.REACT_APP_API_URL}/public/files/${file[0]}`}
                                      alt={file[0]}/>
                        } else if (fileType === 'archive') {
                          return <SvgIcon hash="archive" width="46"/> // Здесь можно поставить иконку для архивов
                        } else if (fileType === 'doc') {
                          return <SvgIcon hash="doc" width="46"/>
                        } else if (fileType === 'other') {
                          return <SvgIcon hash="otherFile" width="46"/>
                        }
                      })()
                    }
                  </li>
                );
              })}
            </ul>
          )
        }
                        </span>
    );
  } else {
    return <h4 className="tc w1">Файлы отсутствуют</h4>;
  }
}
export default Files