// src/pages/TaskCreation/TaskCreationPage.js
import React, {useState, useEffect/*, useCallback*/ } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {useNavigate} from "react-router-dom";
import GoToMP from "../../components/GoToMP";
import useFetch from "../../utils/useFetch";
import formatPhone from "../../utils/formatPhone";
import randStr from "../../utils/randStr";
import SvgIcon from "../../components/SvgIcon";
import DropDown from "../../components/DropDown";
import {setDepartments, addDepartment} from '../../features/departmentSlice';
import {setUsers} from '../../features/usersSingleSlice';
import {setUsers as setUsersMore} from '../../features/usersMoreSlice';
import InputFile from "../../components/InputFile";
// import getFileType from "../../utils/getFileType";
import settings from "../../utils/settings";
import Files from "../../components/Files";
import './CreateTask.css';

const CreateTaskPage = () => {
  window.tmpTodoID=(!window.tmpTodoID)?randStr():window.tmpTodoID;
  const dispatch = useDispatch()
    , departments = useSelector((state) => state.departments.departmentsList)
    , usersSingle = useSelector((state) => state.usersSingle.usersList)
    , usersMore   = useSelector((state) => state.usersMore.usersList)
    , navigate=useNavigate()
    , [addDep,setAddDep]=useState(false)
    , [files,setFiles]=useState([])
    , [checkForm,setCheckForm]=useState([])
    , [fileToDel,setFileToDel]=useState('')
    , [searchUserSingle,setSearchUserSingle]=useState('')
    , [searchUserMore,setSearchUserMore]=useState('')
    , [dep,setDep]=useState('')
    , [activeDepartmentId, setActiveDepartmentId] = useState(null)
    , [name, setName] = useState('')
    , [date, setDate] = useState('')
    , [text, setText] = useState('')
    , [resp, setResp] = useState([])
    , [team, setTeam] = useState([])
    , getDefaultDate = () => {
        const dt = new Date();
        dt.setDate(dt.getDate() + 7); // Добавляем 7 дней к текущей дате
        const formattedDate = dt.toISOString().split('T')[0]; // Форматируем в 'YYYY-MM-DD'
        if(date&&date==='')setDate(formattedDate); // Устанавливаем значение в состояние
        return formattedDate
      }
    , { setFetch: setNewDepartment, dataResponse: setNewDepartmentData } = useFetch('todo/setNewDepartment',dep)
    , { setFetch: getDepartments, dataResponse: getDepartmentsData }     = useFetch('todo/getDepartments','1')
    , { setFetch: get50, dataResponse: get50Data }                       = useFetch('user/get50','1')
    , { setFetch: getSingleSearch, dataResponse: getSearchSingleData }   = useFetch('user/getSearch',searchUserSingle)
    , { setFetch: getMoreSearch, dataResponse: getSearchMoreData }       = useFetch('user/getSearch',searchUserMore)
    , { setFetch: setInviteBySmsS, dataResponse: setInviteBySmsSData }   = useFetch('user/setInviteBySms', {phone:formatPhone(searchUserSingle), t:0, tmpTodoID:window.tmpTodoID})
    , { setFetch: setInviteBySmsM, dataResponse: setInviteBySmsMData }   = useFetch('user/setInviteBySms',{phone:formatPhone(searchUserMore),t:1, tmpTodoID:window.tmpTodoID})
    , { setFetch: setDelFileFromSess/*, dataResponse: setDelFileFromSessData*/ }   = useFetch('file/setDelFileFromSess',fileToDel)
    , { setFetch: setSave, dataResponse: setSaveData }   = useFetch('todo/setSave',{
        name,     date,     depid: activeDepartmentId,     text,      respid: resp[0] ?? 0,     teamid: team.join(',') ?? 0,
        tmpTodoID:window.tmpTodoID
      })
    , handleClcAddDep=()=>setAddDep(e=>!e)
    , handleSaveDep=()=> {
      if(dep&&dep.length<2){
        alert('FER_76. Укажите название');
        return null;
      }
      if(dep&&dep.length>80){
        alert('FER_ERF4. Слишком длинное название. Сократите');
        return null;
      }
        setNewDepartment()
        setAddDep(false)
      }
    , handleSetDep=e=>{
        const v=e.target.value;
        if(v){
          if(v.length>80){
            alert('FER_ERF. Слишком длинное название. Сократите.');
            return null;
          }
          setDep(v)
        }
      }
    , handleDepClick   = depId => {
        setActiveDepartmentId(depId);
        setCheckForm(prev=>{
          // Проверяем, есть ли 1 в массиве prev
          if (!prev.includes(1)) {
            return [...prev, 1];
          }
          return prev; // Если 1 уже есть, возвращаем массив без изменений
        })
      }
    , handleFileChange = async (event) => {
        let filesInput = event.target.files;

        if (filesInput.length > 10) {
          alert('FER_RY7. Вы можете выбрать не более 10 файлов.');
          // Если больше 10 файлов, ограничиваем до 10
          // Преобразуем FileList в массив, чтобы обрезать его длину
          filesInput = Array.from(filesInput).slice(0, 10);
        }
        try {
          const filesForm = new FormData();

          // Добавляем все выбранные файлы в formData
          if(filesInput.length){
            for (let i = 0; i < filesInput.length; i++) {
              filesForm.append('files[]', filesInput[i]);
            }
          }
          filesForm.append('set', 'file/setTodoUploadAllTypesMultiple');
          filesForm.append('location_', window.location.href);
          const tkn=localStorage.getItem('hash');
          if(!tkn)throw new Error('FER_RY7. No token');
          fetch(`${settings.REACT_APP_API_URL}/index.php?fetch=true`, {
            method: 'POST',
            body: filesForm,
            credentials: 'include',
            headers: {  // блок для заголовков
              'Accept': 'application/json, text/plain',
              'X-Token': tkn,
            },
          }).then(r=>r.json()).then(e=>{
            if(e?.s===1){
              const j = JSON.parse(e?.d);
              if (Array.isArray(j)) {
                setFiles(prevFiles => [
                  ...prevFiles,
                  ...j
                ]);
              }
            }
            if(e?.s===0 && e?.d)alert(e?.d)
          })
        } catch (e) {
          console.error('Ошибка:', e);
        }
      }
    , handleSearchSingleUser= e =>{
        const v=e?.target?.value
        if(v.length>80){
          alert('FER_RY4. Разрешено не более 80-ти символов');
          return null;
        }
        if(v&&v?.length&&v.length>1&&v.length<80){
          setSearchUserSingle(v)
        }
      }
    , handleSetSearchSingleUser=()=>{
        // if(searchUserSingle.length<2){
        //   alert('FER_U53. Слишком мало символов для поиска');
        //   return null;
        // }
        if(searchUserSingle.length>80){
          alert('FER_U34. Разрешено не более 80-ти символов');
          return null;
        }
        getSingleSearch()
      }
    , handleSearchMoreUser= e =>{
        const v=e?.target?.value
        if(v.length>80){
          alert('FER_U65. Разрешено не более 80-ти символов');
          return null;
        }
        if(v&&v?.length&&v.length>1&&v.length<81){
          setSearchUserMore(v)
        }
      }
    , handleSetSearchMoreUser=()=>{
        // if(searchUserMore.length<2){
        //   alert('FER_U53. Слишком мало символов для поиска');
        //   return null;
        // }
        if(searchUserMore.length>80){
          alert('FER_U34. Разрешено не более 80-ти символов');
          return null;
        }
        getMoreSearch()
      }
    , handleSetInviteSingleUser= () =>{
        const p=formatPhone(searchUserSingle);
        if(
          p
          &&p.length!==16
        ){
          alert('FER_UGR. Проверьте номер телефона');
          return null;
        }
        setInviteBySmsS()
      }
    , handleSetInviteMoreUser= () =>{
        setInviteBySmsM()
      }
    // , handleRemoveFileFromTodo=(f) => {
    //     setFileToDel(f);
    //     setFiles(prevFiles => prevFiles.filter(file => file[0] !== f));
    //   }
    , handleNameChange = (event) => {
        setName(event.target.value);
        setCheckForm(prev=> {
          // Проверяем, есть ли 1 в массиве prev
          if (!prev.includes(2)) {
            return [...prev, 2];
          }
          return prev; // Если 2 уже есть, возвращаем массив без изменений
        })
      }
    , handleDateChange = (event) => {
        setDate(event.target.value);
      }
    , handleTextChange = (event) => {
        setText(event.target.value);
      }
    , handleSaveTodo = (event) => {
        setSave()
      }
  ;
  // Отделы
  useEffect(() => {
    // Вызываем setNewDepartment для запроса данных о отделах с сервера
    getDepartments();
  }, []);
  useEffect(() => {
    // Когда ответ получен, обновляем Redux
    if (getDepartmentsData) {
      const dep=JSON.parse(getDepartmentsData.d)
      dispatch(setDepartments(dep));
    }
  }, [dispatch, getDepartmentsData]);
  // \ Отделы
  // Пользователи
  useEffect(() => {
    // Вызываем setNewDepartment для запроса данных о отделах с сервера
    get50();
  }, []);
  useEffect(() => {
    // Универсальная обработка данных
    const handleResponse = (data, action) => {
      if (data) {
        const parsedData = JSON.parse(data.d);
        dispatch(action(parsedData));
      }
    };

    // Пользователи для одиночного выбора
    handleResponse(get50Data, setUsers);

    // Пользователи для группового выбора
    handleResponse(get50Data, setUsersMore);
  }, [dispatch, get50Data]);
  // \ Пользователи
  useEffect(() => {
    // Когда ответ получен, обновляем Redux
    if (setNewDepartmentData) {
      const id=setNewDepartmentData.d; // ID
      dispatch(addDepartment({id, title:dep}));
      setDep('')
    }
  }, [dispatch, setNewDepartmentData]);
  useEffect(()=>{
    if(getSearchSingleData&&getSearchSingleData?.s===0) {
      alert(getSearchSingleData.d)
    }
    if(getSearchSingleData&&getSearchSingleData?.s===1&&getSearchSingleData?.d){
      const j=JSON.parse(getSearchSingleData.d)
      dispatch(setUsers(j));
    }
  },[dispatch,getSearchSingleData])
  useEffect(()=>{
    if(getSearchMoreData&&getSearchMoreData?.s===0) {
      alert(getSearchMoreData.d)
    }
    if(getSearchMoreData&&getSearchMoreData?.s===1&&getSearchMoreData?.d){
      const j=JSON.parse(getSearchMoreData.d)
      dispatch(setUsersMore(j));
    }
  },[getSearchMoreData])

  useEffect(()=>{
    if(setInviteBySmsSData&&setInviteBySmsSData.d){
      alert(setInviteBySmsSData.d)
    }
  },[dispatch,setInviteBySmsSData])
  useEffect(()=>{
    if(setInviteBySmsMData&&setInviteBySmsMData.d){
      alert(setInviteBySmsMData.d)
    }
  },[dispatch,setInviteBySmsMData])
  useEffect(()=>{
    if(files.length>10){
      alert('FER_77BBN. Файлов не должно быть более 10-ти штук.');
      setFiles(prevFiles => prevFiles.slice(0, 10));
    }
  },[files])
  useEffect(()=>{
    if(fileToDel&&fileToDel?.length>0){
      setFileToDel('')
      setDelFileFromSess()
    }
  },[fileToDel])
  useEffect(() => {
    if (!date) {
      const defaultDate = getDefaultDate();
      setDate(defaultDate); // Устанавливаем дату по умолчанию
    }
  }, [date]);
  useEffect(() => {
    if (setSaveData&&setSaveData.s&&setSaveData.d) {
      if(setSaveData.s===0){
        alert(setSaveData.d)
      }else{
        const TID=Math.floor(setSaveData.d);
        if(TID>0){
          navigate('/task/'+TID)
        }
      }
    }
  }, [setSaveData]);
  // useEffect(() => {
  //   console.log(checkForm.length)
  // }, [checkForm]);

  return (
    <div className="cnt crtt mobPDx">
      <h1 className="fx ac fsb">
        <span className="fx ac gap">
          <SvgIcon hash="todo" width="36"/>
          Создание задачи
        </span>
        <GoToMP/>
      </h1>
      <hr/>
      <div className="table">
        <div className="table-row">
          <span className="tr fwb table-cell table-txt">Отдел</span>
          <div className="table-cell mobOva">
            <ul className="fx gap.4 mobPDx">
              <li>
                <button
                  className="pr fx ac jc fs0 fwb btnDep btnCrtDep"
                  onClick={handleClcAddDep}
                >+
                </button>
              </li>
              <li>
                {
                  addDep && (
                    <span className="pr">
                      <input
                        type="text" required autoFocus
                        placeholder="Название отдела"
                        className="addDep btnDep"
                        minLength="2" maxLength="80"
                        onInput={handleSetDep}
                        onKeyDown={e => {
                          if (e.key === 'Enter') handleSaveDep()
                        }}
                      />
                      <button
                        className="pa fs0 btn crttEnter"
                        onClick={handleSaveDep}
                      >+
                        <SvgIcon hash="enter" width="20"/>
                      </button>
                    </span>
                  )
                }
              </li>
              {Array.isArray(departments) && departments.map((department) => (
                <li key={department.id}>
                  <button
                    className={`fwb btnDep ${activeDepartmentId === department.id ? 'btnDepCl' : ''}`}
                    onClick={() => handleDepClick(department.id)}
                  >{department.title}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="table-row">
          <span className="tr fwb table-cell table-txt">Название</span>
          <div className="table-cell">
            <input
              type="text" placeholder="Название задачи"
              className="w1 fwb inp fs18 crttName" required
              minLength="3"
              onChange={handleNameChange}
            />
          </div>
        </div>
        <div className="table-row">
          <span className="tr fwb table-cell table-txt">Ответственный</span>
          <div className="table-cell">
            <DropDown options={usersSingle} cb={{
              input_: handleSearchSingleUser,
              setSearch_: handleSetSearchSingleUser,
              setInvite_: handleSetInviteSingleUser,
              setUsers_:   setResp,
              defVal:     searchUserSingle
            }}/>
          </div>
        </div>
        <div className="table-row">
          <span className="tr fwb table-cell table-txt">Команда</span>
          <div className="table-cell">
            <DropDown options={usersMore} multiple={true} cb={{
              input_:     handleSearchMoreUser,
              setSearch_: handleSetSearchMoreUser,
              setInvite_: handleSetInviteMoreUser,
              setUsers_:   setTeam,
              defVal:     searchUserMore
            }}/>
          </div>
        </div>
        <div className="table-row">
          <span className="tr fwb table-cell table-txt">Дедлайн</span>
          <div className="table-cell">
            <div className="fx gap1 fsb ac mobFdc mobAis">
              <input
                type="date"
                className="fwb w1/3 inp"
                value={date}
                onChange={handleDateChange}
              />
              <span className="fx fsb ac gap1">
                { (files && files.length > 0) && <Files files={files}/> }
                <label className="cp fx fsb ac gap1 addFile">
                  <span className="fx ac jc crttBtnAddFile">
                    <SvgIcon hash="file" width="20"/>
                  </span>
                  <InputFile handleFileChange={handleFileChange}/>
                  <span className="c6">Выбрать файл</span>
                </label>
              </span>
            </div>
          </div>
        </div>
        <div className="table-row">
          <span className="tr fwb table-cell table-txt">Описание</span>
          <span className="table-cell">
            <textarea
              className="w1"
              rows="8"
              onChange={handleTextChange}
            ></textarea>
          </span>
        </div>
        <div className="table-row">
          <span className="table-cell">&nbsp;</span>
          <div className="table-cell">
            <span className="fx fdc gap fafs">
              {
                !checkForm.includes(1) && <mark className="fx ac gap"><SvgIcon hash="info"/> Необходимо выбрать отдел</mark>
              }
              {
                !checkForm.includes(2) && <mark className="fx ac gap"><SvgIcon hash="info"/> Необходимо ввести название</mark>
              }
              <button
                className="w1/2 cf fs fwb py1 mt2 mb crttBtnSave"
                onClick={handleSaveTodo}
                disabled={checkForm.length !== 2}
              >Создать задачу</button>
            </span>
          </div>
        </div>
      </div>
      {/* \ table*/}
    </div>
  )
};

export default CreateTaskPage;