import React from "react";

const Image=({URL = '',class_=''})=>{
  return (URL!=='')?
    (
      <picture>
        {/*<source srcSet={`${imageUrl}.avif`} type="image/avif"/>*/}
        <source srcSet={`${URL}.webp`} type="image/webp"/>
        <img
          src={`${URL}.jpg`}
          alt="avatar"
          width="62"
          className={`br50 ava ${class_}`}
        />
      </picture>
    ):
    <div className={`bgf br50 ava avaNS ${class_}`}>&nbsp;</div>
}
export default Image