import React from 'react';
import {useNavigate} from "react-router-dom";

const GoToMP = () => {
  const navigate = useNavigate()
      , handleGotoMP=()=>navigate('/')
  return <button
    className="pr fx ac jc fs0 br50 nav"
    onClick={handleGotoMP}
    title="Перейти на главную"
  >x</button>
}
export default GoToMP;