// src/features/departmentSlice.js
import { createSlice } from '@reduxjs/toolkit';

const departmentSlice = createSlice({
  name: 'departments',
  initialState: {
    departmentsList: [],
  },
  reducers: {
    setDepartments: (state, action) => {
      state.departmentsList = action.payload;
    },
    addDepartment: (state, action) => {
      state.departmentsList.push(action.payload);
    },
    removeDepartment: (state, action) => {
      state.departmentsList = state.departmentsList.filter(
        (department) => department.id !== action.payload
      );
    },
  },
});

export const { setDepartments, addDepartment, removeDepartment } = departmentSlice.actions;
export default departmentSlice.reducer;
